/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.AmastyReturnOption {
    select {
        height: 60px;
        border: 1px solid $default-neutral-base-color;

        @include ultra-narrow-desktop {
            height: 50px;
            padding: 5px 10px;
            min-width: 65px;
        }

        @include tablet {
            height: 50px;
            padding: 5px 10px;
            min-width: 65px;
        }

        @include mobile {
            height: 50px;
            padding: 5px 10px;
            min-width: 65px;
        }
    }
}