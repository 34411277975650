/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --content-wrapper-width: 94vw;
    --content-wrapper-large: 94vw;
    --content-wrapper-nav-width: 94vw;

    @include mobile {
        --content-wrapper-width: calc(100vw - 36px);
        --content-wrapper-large: calc(100vw - 36px);
    }
}

.ContentWrapper {
    padding-inline: 0;
}

.Breadcrumbs {
    .ContentWrapper {
        max-width: var(--content-wrapper-large);
        padding-inline: 0;

        @include mobile {
            max-width: calc(100% - 36px);
        }
    }
}
