/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.StoreFinder {
    margin-block-end: 100px;

    @include mobile {
        margin-block-end: 0; 
    }

    .StoreLocator-Header {
        padding-inline: 5.2%;
        background-color: #ebeff2;
        margin-block-end: 70px;

        @include narrow-desktop {
            padding-inline: 0;
        }

        @include tablet {
            padding-inline: 0;
        }

        @include mobile {
            padding-inline: 0;
            margin-block-end: 0;
        }

        .pagebuilder-column-group {
            @include ultra-narrow-desktop {
                max-height: 450px;
            }

            @include tablet {
                max-height: 450px;
            }
        }

        .store-column-first {
            @include mobile {
                display: none !important;
            }

            figure {
                @include desktop {
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    overflow: hidden;
                }

                img {
                    @include desktop {
                        width: auto;
                    }
                }
            }
        }

        .store-column-second {
            background-color: $black;
            color: $white;
            padding-inline-start: 5%;
            padding-inline-end: 16%;
            padding-block: 6% 4%;

            @include mobile {
                width: 100% !important;
                padding-inline-start: 18px;
                padding-inline-end: 18px;
                padding-block-start: 20px;
            }

            figure {
                max-width: 135px;
                max-height: 140px;

                @include mobile {
                    max-width: 70px;
                    max-height: 75px;
                }
            }

            h2 {
                color: $white;
                font-size: 4.2rem;
                margin-block-end: 10px;

                @include mobile {
                    font-size: 2.7rem;
                    margin-block-start: 10px;
                }
            }

            p {
                font-style: italic;
                font-size: 1.4rem;
                color: $white;
            }
        }
    }

    &-Wrapper {
        margin-inline: 12px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: 100vw;
        padding-block-end: 80px;

        @include mobile {
            display: block;
            padding: 0;
        }
    }

    &-SelectList {
        display: flex;
        flex-direction: row;
        grid-gap: 20px;
        gap: 20px;
        margin-bottom: 50px;
        align-items: baseline;

        @include mobile {
            grid-gap: 0;
            gap: 0;
            margin-bottom: 30px;
        }
        
        h5 {
            font-size: 2.4rem;
            font-weight: normal;
            color: #3A3939;

            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
            }
        }

        @include mobile {
            flex-direction: column;
        }
    }

    &-Select {
        width: 100%;
        max-width: 500px;

        @include desktop-wide-1600 {
            max-width: 460px;
        }

        @include desktop-wide-1440 {
            max-width: 400px;
        }

        @include ultra-narrow-desktop {
            max-width: 400px;
        }

        @include tablet {
            max-width: 400px;
        }
    }

    &-StoreContent {
        display: flex;
        grid-gap: 60px;

        @include desktop-wide-1600 {
            grid-gap: 40px;
        }

        @include tablet {
            grid-gap: 40px;
        }

        @include mobile {
            flex-direction: column;
            grid-gap: 40px;
        }
    }

    &-StoreCards {
        display: flex;
        flex-direction: column;
        max-height: 660px;
        max-width: 504px;
        width: 100%;
        overflow-y: scroll;
        border: 1px solid $black;
        padding: 36px 28px;

        &::-webkit-scrollbar  {
            width: 7px;
            background-color: #DEDEDE;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $black; 
            border-radius: 0px; 
        }

        @include desktop-wide-1600 {
            max-width: 570px;
            max-height: 660px;
        }

        @include desktop-wide-1440 {
            max-width: 510px;
            max-height: 650px;
        }

        @include narrow-desktop {
            max-width: 460px;
            max-height: 630px;
        }

        @include ultra-narrow-desktop {
            max-width: 455px;
            max-height: 580px;
        }

        @include tablet {
            max-width: 400px;
            max-height: 480px;
        }

        @include mobile {
            grid-template-columns: 1fr;
            max-width: 100%;
            max-height: 540px;
            overflow-y: auto;
            padding: 0;
            padding-inline-end: 18px;
            border: none;
        }
    }

    &-Store {
        display: flex;
        padding-block-end: 25px;
        border-block-end: 1px solid #d1d1d1;
        margin-block-end: 25px;

        @include mobile {
            text-align: center;

            &:last-child {
                border-block-end: none;
                margin-block-end: 0;
            }
        }
    }

    &-Map {
        max-width: 1140px;
        width: 100%;

        @include mobile {
            width: 100vw;
            margin-inline-start: -18px;
            height: 610px;
        }

        .leaflet-container {
            z-index: 0;
            border: var(--leaflet-map-border);
            max-height: 660px;
            height: 100%;

            @include mobile {
                border: none;
            }
        }

        .leaflet-popup-content-wrapper {
            box-shadow: none;
            border-radius: 3px;
            width: 100%;
            min-width: 330px;
            min-height: 330px;
            height: fit-content;
        }

        .leaflet-popup-content {
            width: 100% !important;
            height: fit-content;
        }
    }

    .ContentWrapper {
        max-width: var(--content-wrapper-large);
        margin-inline: auto;
        
        @include mobile {
            max-width: calc(100% - 36px);
        }
    }
}
