/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --popup-background: #000000a3;

    @include mobile {
        --popup-content-padding: 20px;
    }

    --popup-content-background: #{$white};
    --popup-min-width: 100%;
    --popup-content-padding: 12px;
    --popup-max-height: 80%;

    @include desktop {
        --popup-min-width: 490px;
        --popup-content-padding: 24px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    &_isVisible {
        pointer-events: all;
        opacity: 1;

        @include desktop {
            width: 100vw;
            height: 100vh;
            inset-block-start: 0;
        }

        @include mobile {
            z-index: 999;
            width: 100vw;
            height: 100vh;
            inset-block-start: 0;
            overflow-y: scroll;
            align-items: flex-start;
        }

        &.Info-Popup {
            p, h2, h3, h4 {
                color: #ffffff;
            }

            h2 {
                font-size: 2.1rem;
                font-weight: 600;
                margin-block-end: 40px;
            }

            h5 {
                margin-block-start: 40px;
                font-size: 1.6rem;
                font-weight: bold;
                color: #ffffff;
            }

            p {
                font-size: 1.6rem;
                font-weight: normal;
                font-family: $font-muli;
            }

            .Popup {
                &-Content {
                    background-color: $default-primary-base-color;
                    max-width: 978px;
                    max-height: 590px;
                    padding: 0%;
                    overflow-y: hidden;

                    @include mobile {
                        overflow-y: auto;
                        max-height: 100%;
                    }

                    &::after {
                        @include desktop {
                            display: none;
                        }
                    }

                    .Popup {
                        &-Header {
                            padding: 0;

                            .Popup-CloseBtn {
                                @include mobile {
                                    inset-block-start: 20px;
                                    inset-inline-start: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .popup-eco {
                background-color: $default-primary-light-color;
                color: $black;
                max-width: 895px;
                width: 100%;
                height: 500px;
                padding-block: 50px;
                display: flex;
                justify-content: center;
                align-content: center;
                padding-inline: 10%;

                @include mobile {
                    height: 100%;
                    padding-inline: 22px;
                    padding-block: 0 40px;
                }

                h4 {
                    font-size: 2.4rem;
                    font-weight: 700; 
                    color: $black;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    &::before {
                        position: relative;
                        content: var(--popup-cart);
                    }

                    @include mobile {
                        line-height: 1.3;
                    }
                }

                p {
                    font-size: 1.6rem;
                    font-weight: normal;
                    margin-block-start: 35px;
                    color: $black;
                    text-align: center;

                    @include mobile {
                        margin-block-start: 10px;
                    }
                }
            }

            @include mobile {
                .popup-eco {
                    .pagebuilder-column-group {
                        flex-direction: column;
                    }

                    .pagebuilder-column {
                        width: 100% !important;
                    }

                    figure {
                        img {
                            width: calc(100% + 2px);
                            margin-inline-start: -2px;

                            @include mobile {
                                height: 315px;
                            }
                        }
                    }

                    h2 {
                        font-size: 1.8rem;
                        margin-block-end: 20px;
                        margin-block-start: 40px;
                    }

                    h5 {
                        margin-block-start: 0;
                        font-size: 1.4rem;
                    }

                    p {
                        font-family: $font-base;
                        font-size: 1.3rem;
                        line-height: 1.5;

                        & + p {
                            margin-block-start: 20px;
                        }
                    }
                }
            }
        }
    }

    &-Heading {
        text-decoration: none;
        font-weight: 600;
        font-size: 2rem;
        margin-block-start: 0;
    }

    &-Header {
        padding-block-start: 40px;

        @include mobile {
            display: block;
            padding-block-start: 0;
            margin-block-end: 30px;
        }
    }

    &-Content {
        background-color: var(--popup-content-background);
        border-radius: 0;
        padding: 0 75px 40px;
        padding-block-end: 40px;
        min-width: var(--popup-min-width);
        max-width: 100%;
        overflow-x: hidden;
        max-height: var(--popup-max-height);
        overflow-y: auto;

        &::after {
            content: '';
            display: block;
            padding-block-end: var(--popup-content-padding);
        }

        @include mobile {
            border-radius: 0;
            max-height: unset;
            min-height: 100%;
            height: fit-content;
            padding: 0 var(--popup-content-padding) 30px;
            z-index: 20;
        }
    }

    & &-CloseBtn {
        position: absolute;
        inset-inline-end: 24px;
        inset-block-start: 24px;
        z-index: 5;

        .CloseIcon {
            height: 20px;
            width: 20px;
        }

        @include mobile {
            inset-inline-end: 0;
            inset-block-start: 0;
            position: relative;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }

    &.RightSidebar {
        justify-content: flex-end;
        align-items: flex-start;
        transition: transform 200ms ease-in-out;
        transform: translateX(100%);
        will-change: transform;
    
        @include mobile {
            justify-content: center;
            align-items: center;
        }
        
        &_isVisible {
            transform: translateY(0%);
        }

        .Popup {
            &-Content {
                position: relative;
                background-color: #fff;
                overflow-y: scroll;
                z-index: 10;
    
                @include desktop {
                    border: 7px solid #fff;
                    width: 400px;
                    min-width: 400px;
                    height: 100%;
                    max-height: 100vh;
                    padding: 43px;
                }
    
                @include mobile {
                    width: 100%;
                    height: fit-content;
                    max-height: unset;
                    min-height: 100vh;
                    padding: 24px 24px 30px;
                }
            }

            &-CloseBtn {
                inset-inline-end: auto;
                inset-inline-start: 0;
                inset-block-start: 0;
            }
        }        
    }
}

.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: fixed;
        overflow-y: scroll;
    }

    @include mobile {
        overflow: hidden;

        #root {
            height: 100vh;
            overflow: hidden;
        }
    }
}
