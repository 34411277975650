/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

.Menu {
    &-Item {
        padding-inline-start: 0;

        @include desktop {
            height: auto;
            margin-inline: 17px;
        }

        @include desktop-wide-1600 {
            margin-inline: 14px;
        }

        @include narrow-desktop {
            margin-inline: 10px;
        }

        @include ultra-narrow-desktop {
            margin-inline-start: 10px;
            margin-inline-end: 5px;
        }

        @include tablet {
            margin-inline-start: 10px;
            margin-inline-end: 5px;
        }

        @include mobile {
            margin-inline: 0;

            &:last-child {
                margin-block-end: 6px;
            }
        }

        &Caption {
            color: var(--color-black);

            &:hover {
                color: var(--primary-base-color);
            }

            @include mobile {
                margin-inline: 0;
            }

            @include mobile {
                padding-block: 7px;
            }

            &_isSecondLevel {
                font-weight: 500;
            }

            &_type {
                &_main {
                    @include desktop {
                        margin: 0 0 0;
                    }

                    font-family: $font-muli;
                    text-transform: none;
                    font-size: 1.6rem;
                    font-weight: 400;
                    line-height: 1.4;
                    letter-spacing: 0.01rem;
                    padding-block-end: var(--menu-item-bottom-padding);
                    padding-block-start: 54px;
                    white-space: nowrap;

                    @include narrow-desktop {
                        font-size: 1.4rem;
                    }

                    @include ultra-narrow-desktop {
                        font-size: 1.6rem;
                    }

                    @include tablet {
                        font-size: 1.6rem;
                    }

                    @include mobile {
                        text-transform: none;
                        line-height: 1.3;
                        font-weight: 700;
                        padding-block-end: 13px;
                        padding-block-start: 13px;
                    }
                }

                &_subcategory {
                    line-height: 20px;
                    text-transform: uppercase;

                    @include desktop {
                        margin-block-start: 0;
                        display: inline-block;
                        padding-block-end: 15px !important;
                    }

                    @include mobile {
                        margin-inline-end: 0;
                    }
                }
            }
        }

        &List {
            &_type_subcategory {
                @include mobile {
                    padding-block-start: 0;
                    padding-block-end: 10px;

                    .Menu-ExpandedState {
                        --plus-minus-icon-size: 21px;

                        inset-block-start: 2px;
                    }
                }
            }
        }

        &:not(:first-of-type) {
            @include desktop {
                margin-inline-start: 17px;
            }

            @include desktop-wide-1600 {
                margin-inline-start: 14px;
            }

            @include tablet {
                margin-inline-start: 10px;
            }
        }

        &.Promo {
            @include narrow-desktop {
                margin-inline-start: 4px;
            }

            @include ultra-narrow-desktop {
                margin-inline-start: 4px;
            }
            
            @include tablet {
                margin-inline-start: 4px;
            }

            .Menu-ItemCaption {
                @include narrow-desktop {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 53px;
                }

                @include ultra-narrow-desktop {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 53px;
                }
    
                @include tablet {
                    width: auto;
                    padding: 6px 10px;
                    border-radius: 10px;
                    margin-block-start: 34px;
                }
            }
        }
    }

    &-Link {
        cursor: pointer;

        @include ultra-narrow-desktop {
            font-size: 11px;
        }

        @include tablet {
            font-size: 12px;
        }

        &_isHovered {
            .Menu-ItemCaption_type_main {
                color: $default-primary-base-color;

                &::after {
                    @include desktop {
                        content: "";
                        display: block;
                        position: absolute;
                        inset-block-end: 0;
                        inset-inline-start: -17px;
                        width: calc(100% + 34px);
                        height: 6px;
                        background-color: $default-primary-base-color;
                    }
                }
            }
        }
    }

    &-Sub {
        &ItemWrapper {
            padding-inline: 30px;
            break-inside: avoid;
            overflow: hidden;

            > .Menu-Link {
                text-transform: uppercase;

                @include desktop {
                    font-size: 1.6rem;
                    font-weight: 700;
                }
            }
        }

        &Menu {
            @include mobile {
                margin-block-end: 35px;
            }

            &_isVisible {
                background: transparent;
                margin-block-end: 0;

                @include mobile {
                    margin-block-end: 0;
                    padding-inline-start: 20px;
                }
            }

            .Menu-ItemCaption {
                @include ultra-narrow-desktop {
                    padding-block-end: 2px;
                }

                @include tablet {
                    padding-block-end: 2px;
                }

                @include mobile {
                    padding-block: 4px;
                }
            }
        }
    }

    &-SubCategoriesWrapper {
        @include desktop {
            padding-block: 0;
            padding-inline: 0 50px;
            position: absolute;
            background: #fff;
            inset-block-start: 99px;
            inset-inline-start: 2.5%;
            width: var(--content-wrapper-large);
            z-index: 999;
            box-shadow: 0px 5px 5px 0px rgba(35,31,32,.1);
        }

        @include desktop-wide-1440 {
            inset-block-start: 97px;
        }

        @include narrow-desktop {
            inset-block-start: 95px;
        }

        @include tablet {
            inset-block-start: 95px;
        }
    }

    &-SubCategoriesWrapperInner {
        @include ultra-narrow-desktop {
            margin-inline-start: -16px;
        }

        @include tablet {
            margin-inline-start: -16px;
        }
    }

    &-SubCategories {
        padding-block: 45px;
        padding-inline-start: 10px;
    }

    &-SubLevelDesktop {
        font-size: 1.4rem;
    }

    &-ExpandedState {
        --plus-minus-icon-size: 24px;

        inset-block-start: 2px;

        @include mobile {
            inset-block-start: 9px;
        }
    }

    &-RightButtonsWrapper {
        @include mobile {
            .Button {
                background-color: #fff;
                border: none;
                color: $black;
                display: block;
                font-size: 16px;
                line-height: 1.3;
                padding: 0;
                height: auto;
                letter-spacing: 0.06rem;
                padding-block: 7px;
                margin-block-end: 6px;

                &.newProducts {
                    color: $default-secondary-base-color;
                }

                &.sales {
                    color: $default-primary-base-color;
                }
            }
        }
    }

    @include mobile {
        &-Additional {
            font-family: $font-muli;
            margin-block-start: 15px;
            display: flex;
            flex-direction: column;
            row-gap: 0;
            font-size: 2.1rem;
            font-weight: 400;
            padding-inline: 19px;
    
            .Menu-Button {
                display: block;
                width: 100%;
                font-family: $font-muli;
                font-size: 2.1rem;
                font-weight: 400;
                padding-block: 11px 10px;
                border-bottom: 1px solid #C6C6C6;

                svg {
                    vertical-align: top;
                    margin-inline-end: 15px;

                    path {
                        stroke: $black;
                    }
                }

                &.Wishlist {
                    svg {
                        transform: scale(0.9);
                        margin-inline-start: -5px;
                        margin-inline-end: 10px;
                    }
                }
            }

            .Header-StoreButtonWrapper a::before {
                margin-block-start: 0;
                margin-inline-end: 13px;
            }
        }
    }
}

.MenuPage {
    @include desktop {
        display: none;
    }
    
    @include mobile {
        inset-block-start: 0;
        inset-block-end: 0;
        position: fixed;
        margin-block: 0;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100vw;
        min-height: unset;
        padding-block-start: 0;
        padding-inline: 0;
        background-color: $white;
        z-index: 999;
        transform: translateX(-100%);
        transition: all 200ms;
    }

    &.isVisible {
        transform: translateX(0);
    }

    &#MenuMobile {
        .Logo {
            text-align: center;
            margin-block-end: 0;
            height: 54px;
    
            @include desktop {
                display: none;
            }
    
            img {
                @include mobile {
                    position: relative;
                    max-width: 198px;
                }
            }
        }
    }

    &-Close {
        @include mobile {
            position: fixed;
            inset-block-start: 0;
            inset-inline-start: 2px;
            inset-inline-end: auto;
            width: 60px !important; /* stylelint-disable-line declaration-no-important */
            height: 55px;
            text-align: center;
            background-color: #fff;
            z-index: 9999;
            display: none;

            svg {
                vertical-align: middle;
            }

            &.isVisible {
                display: block;
            }
        }

        @include desktop {
            display: none;
        }

        @include tablet {
            display: none;
        }
    }

    .Menu-MainCategories {
        @include mobile {
            padding-inline: 19px;
            padding-block-start: 30px;

            .Menu {
                &-ItemList {
                    border-bottom: 3px solid #C6C6C6;

                    &_type_subcategory {
                        border-bottom: none;
                    }
                }

                &-SubMenu {
                    &_isVisible {
                        border-bottom: 1px solid #C6C6C6;

                        .Menu-SubMenu_isVisible {
                            border-bottom: none;
                        }
                    }
                }
            }
    
            .Menu-ItemCaption {
                &_type {
                    &_main {
                        font-size: 2.1rem;
                        font-weight: 400;
                        padding-block: 10px;
                        border-bottom: 1px solid #C6C6C6;
                    }

                    &_subcategory {
                        text-transform: none;
                        line-height: 25px;
                    }
                }

                &_isExpanded {
                    border-bottom: none;
                }
            }

            .Menu-Item {
                margin-block-end: 0;

                &:last-child {
                    .Menu-ItemCaption {
                        height: auto;
                        padding-inline: 0;
                        font-weight: 700;
                        line-height: 1.2;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

@include mobile {
    #conveythis {
        margin-block-end: 30px;
        margin-inline-start: -10px;
    }
}

.Footer-Content {
    .Menu-SubCategoriesWrapper {
        display: none;
    }
}

