/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override sidea */

:root {
    --search-bar-max-width: 294px;
    --search-bar-width: 270px;
    --search-clear-background: #F2F2F2;
    --search-field-background: #F2F2F2;
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: #000;
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #E3E3E3;
}

.SearchField {
    width: 100%;
    margin-block-start: 0;
    margin-block-end: calc(var(--menu-item-bottom-padding) - 7px);
    margin-inline-start: 30px;

    @include desktop {
        max-width: var(--search-bar-max-width);
    }

    @include desktop-wide-1440 {
        margin-inline-start: 20px;
        margin-inline-end: 15px;
    }

    @include ultra-narrow-desktop {
        margin-inline-start: 5px;
        margin-inline-end: 12px;
    }

    @include tablet {
        margin-inline-start: 5px;
        margin-inline-end: 12px;
        height: 34px;
        width: 35px;
    }
    
    &-SearchInnerWrapper {
        @include tablet {
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: 0;
            padding-inline-end: 0;
        }
    }

    &-Input {
        border: none;
        background-color: var(--search-field-background);
        height: 38px;
        font-size: 12px;
        font-weight: 400;

        @include desktop {
            min-width: 294px;
        }

        @include desktop-wide-1600 {
            min-width: 200px;
        }

        @include desktop-wide-1440 {
            min-width: 130px;
            max-width: 200px;
        }

        @include ultra-narrow-desktop {
            min-width: 130px;
            max-width: 180px;
            padding-inline-start: 15px;
        }

        @include tablet {
            height: 30px;
            padding-block-end: 15px;
            min-width: 0;
            max-width: 0;
            border-bottom: none;
            background-color: transparent;
            padding-inline-start: 15px;

            &_isActive {
                width: 210px;
                max-width: 210px;
                background-color: var(--search-field-background);

                &::placeholder {
                    color: $black;
                }
            }
        }

        @include mobile {
            height: 45px;
            padding: 0px 15px;
            font-size: 1.4rem;
            border: 2px solid $black;
        }

        &:focus {
            border: none;
            border-bottom: 1px solid $default-primary-base-color;

            @include tablet {
                border-bottom: none;
            }
        }

        &::placeholder {
            color: $black;

            @include tablet {
                color: transparent;
            }

            @include mobile {
                color: $black;
                display: block;
            }
        }
    }

    &-CloseIcon,
    &-SearchIcon {
        inset-block-start: 10px;
        inset-inline-end: 10px;

        @include ultra-narrow-desktop {
            svg {
                width: 20px;
            }
        }

        @include mobile {
            inset-block-start: 12px;
            
            svg {
                width: 24px; 
            }
        }
    }

    &-SearchIcon {
        @include tablet {
            z-index: -1;
            inset-inline-end: 5px;
        }
    }

    &-CloseIcon {
        height: 20px;
        width: 20px;

        @include tablet {
            inset-block-start: 7px;
            inset-inline-end: 7px;
        }

        svg {
            width: 17px;

            @include ultra-narrow-desktop {
                width: 13px;
            }
        }
    }
}
