/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends and override sidea */

.Footer {
    --footer-background-color: #fff;

    $column-count: 4;

    @include desktop {
        margin-block-start: 5%;
    }

    @include mobile {
        margin-block-start: 40px;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: var(--footer-background-color);
        padding-block-start: 14rem;
        padding-block-end: 6rem;

        @include mobile {
            padding-block-start: 50px;
            padding-block-end: 35px;
        }
    }

    &-Columns {
        padding-inline: 8%;
        padding-block-start: 0;
        justify-content: flex-start;

        @include ultra-narrow-desktop {
            padding-inline: 2%;
        }

        @include tablet {
            padding-inline: 2%;
        }

        @include mobile {
            display: block;
            text-align: start;
            padding-inline: 0;
            max-width: 100%;
        }
    }

    .Logo {
        width: 200px;
        height: 33px;
        margin-block-end: 30px;

        @include mobile {
            margin-block-end: 15px;
        }
    }

    &-Column {
        @include mobile {
            display: block;
            width: 100%;
        }

        &.ExpandableContent {
            @include mobile {
                border-block-end: 1px solid #E2E2E2;
                padding-inline: 24px;

                &#Prodotti {
                    border-block-start: 1px solid #E2E2E2;
                }
            }

            .ExpandableContent-Heading {
                @include mobile {
                    font-size: 1.6rem;
                }
            }

            .minusIcon {
                @include mobile {
                    font-size: 30px;
                    line-height: 1;
                    margin-inline-end: 6px;
                    position: absolute;
                    inset-inline-end: 0;
                }
            }

            .ExpandableContent-Content_isContentExpanded {
                margin-block-start: 0;
            }
        }

        &:not(:last-of-type) {
            @include desktop {
                padding-inline-end: 0;
                width: calc(100% / #{ $column-count });
            }

            @include mobile {
                margin-block-end: 0;
            }
        }

        &:nth-child(4) {
            @include mobile {
                width: 100%;
            }
        }

        &:last-child {
            &::after {
                content: var(--icone-carte-footer);
                display: block;
                margin-block-start: 10px;

                @include mobile {
                    display: none;
                }
            }

            .Footer-ColumnItem {
                &:last-child {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }

        &.InfoAddress {
            @include desktop {
                margin-inline-end: 7%;
            }

            @include mobile {
                width: 100%;
                text-align: center;
            }

            p {
                font-family: $font-muli;
                font-size: 1.4rem;
                margin-block-end: 0;
                
                strong {
                    display: block;
                    font-size: 1.6rem;
                    margin-block-end: 5px;
                }
            }

            .CmsBlock-Wrapper {
                @include tablet {
                    width: 200px;
                }

                @include mobile {
                    div {
                        div {
                            justify-content: center !important;
                        }
                    }
                }
            }

            .Social-box {
                display: flex;
                align-items: flex-start;
                margin-block-start: 68px;

                @include mobile {
                    margin-block-start: 20px;
                    margin-block-end: 35px;
                    justify-content: center;
                    gap: 24px;
                }

                div {
                    margin-inline-end: 24px;

                    @include mobile {
                        margin-inline-end: 0;
                    }

                    img {
                        height: 24px;
                        width: auto;
                    }
                }
            }
        }
    }

    &-ColumnContent {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @include ultra-narrow-desktop {
            gap: 8px;
        }

        @include tablet {
            gap: 5px;
        }

        @include mobile {
            gap: 3px;
            padding-block-end: 15px;
        }

        .Menu {
            &-MenuWrapper {
                margin-block-start: 0;
            }

            &-MainCategories {
                margin: 0;
            }

            &-ItemList {
                flex-direction: column;
                gap: 10px;
            }

            &-Item {
                margin-inline-end: 0;
                margin-inline-start: 0;

                &:not(:first-of-type) {
                    margin-inline-start: 0;
                }

                &:last-child {
                    a {
                        figcaption {
                            border: none;
                            margin-block-start: 0;
                            padding: 0;
                            text-transform: none;

                            @include mobile {
                                margin-block-start: 0;
                                height: auto;
                                line-height: 1;
                            }
                        }
                    }

                    .Menu-ItemCaption {
                        text-align: start;

                        @include mobile {
                            margin-block-start: 0;
                            padding: 10px 0;
                            height: auto;
                        }
                        
                        &:hover {
                            background-color: transparent;
                            color: $default-primary-base-color;
                        }
                    }
                }
            }

            &-ItemCaption_type_main {
                padding-block: 0;
                color: $black;
                font-size: 1.4rem;
                font-weight: 500;
                line-height: 22px;
                white-space: nowrap;

                @include mobile {
                    padding-block: 5px;
                }

                &#Scarpe,
                &#Collezioni {
                    font-weight: 500;
                }
            }

            &-Link_isHovered {
                .Menu-ItemCaption_type_main {
                    color: $default-primary-base-color;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    &-ColumnItem {
        border-radius: 0;
        color: $black;
        margin-block-end: 0;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 22px;
        white-space: nowrap;

        &:hover {
            color: var(--link-hover);
            text-decoration: none;
        }

        @include mobile {
            white-space: normal;
            padding-block: 6px;
        }
    }

    &-LastColumn {
        padding-inline-start: 8%;

        @include desktop-wide-1440 {
            padding-inline-start: 4%;
        }

        @include narrow-desktop {
            padding-inline-start: 4%;
            width: 30%;
        }

        @include ultra-narrow-desktop {
            padding-inline-start: 4%;
            width: 30%;
            min-width: 200px;
        }

        @include tablet {
            padding-inline-start: 0;
            min-width: 200px;
        }

        @include mobile {
            padding-inline-start: 0;
        }

        .Footer-ColumnTitle {
            width: 50%;
            display: inline-block;
        }

        .phone {
            font-family: $font-base;
            font-size: 2.4rem;
            font-weight: 500;
            margin-block-end: 18px;
            margin-block-start: 0;

            @include ultra-narrow-desktop {
                font-size: 2rem;
            }

            @include tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                width: 50%;
                display: inline-block;
                margin-block-end: 0;
                font-size: 1.8rem;
                font-weight: 500;
            }

            &::before {
                content: var(--icon-phone);
                margin-inline-end: 8px;

                @include mobile {
                    vertical-align: top;
                }
            }            
        }

        .p14 {
            br {
                display: none;
            }
        }

        .CmsBlock-Wrapper {
            div {
                div {
                    @include mobile {
                        display: block !important;  /* stylelint-disable-line declaration-no-important */
                    }

                    .p14 {
                        @include desktop-wide-1440 {
                            font-size: 1.2rem;
                        }
                
                        @include narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include ultra-narrow-desktop {
                            font-size: 1.2rem;
                        }

                        @include tablet {
                            font-size: 1rem;
                        }
                    }

                    .p12 {
                        @include mobile {
                            line-height: 1.5;
                        }

                        & br {
                            @include narrow-desktop {
                                display: none;
                            }

                            @include mobile {
                                display: none;
                            }
                        }
                    }
                }
            }

            .image-nofull, .image-nofull img {
                @include ultra-narrow-desktop {
                    width: 100%;
                    height: auto;
                }

                @include tablet {
                    width: 100%;
                    height: auto;
                }
            }
        }

        .link-primary,
        a {
            font-size: 12px;
            font-weight: 600;
            text-decoration: underline;

            @include mobile {
                font-size: 1.4rem;
            }

            &:hover {
                color: $black;
            }
        }

        .shopping-sicuro {
            &::after {
                content: var(--loghi-secure);
                display: block;
                margin-block-start: 3px;
            }
        }
    }

    &-ColumnTitle {
        display: block;
        white-space: nowrap;
        margin-block-end: 3.2rem;

        @include mobile {
            margin-block-end: 12px;
        }
    }

    &::after {
        display: block;
        content: var(--background-green);
        width: 100%;
        overflow: hidden;

        @include narrow-desktop {
            max-height: 200px;
        }

        @include ultra-narrow-desktop {
            max-height: 200px;
        }

        @include tablet {
            max-height: 160px;
        }

        @include mobile {
            display: none;
        }
    }

    &-CopyrightContentWrapper {
        background-color: transparent;
        padding-inline: 8%;
        padding-block-end: 120px;
        margin-block-start: 0;

        @include ultra-narrow-desktop {
            padding-inline: 3%;
            padding-block-end: 90px;
        }

        @include tablet {
            padding-inline: 3%;
            padding-block-end: 90px;
        }

        @include mobile {
            max-width: 100%;
            padding-inline: 0;
            padding-block-end: 0;
            margin-block-start: 0;
        }
    }

    &-CopyrightContent {
        padding: 0;
        justify-content: space-between;
        font-size: 10px;

        @include mobile {
            padding: 0px 18px 18px;
            flex-direction: column;
            row-gap: 7px;
            font-size: 12px;
        }

        .Footer-Copyright {
            color: $black;
            padding-inline: 0;

            @include mobile {
                font-size: 12px;
            }
        }

        a {
            font-size: 12px;
            font-weight: 400;
            margin-inline-end: 10px;
            text-decoration: underline;
            
            @include mobile {
                font-size: 12px;
            }
        }
    }

    &-BottomContent {
        @include mobile {
            text-align: center;
            font-weight: 500;

            &::after {
                content: var(--icone-carte-footer-mobile);
                display: block;
                margin-block-start: 8px;
            }
        }
    }

    &-PrivacyLinks {
        width: 18%;

        @include narrow-desktop {
            width: 23%;
        }

        @include ultra-narrow-desktop {
            width: 24%;
        }

        @include tablet {
            width: 24%;
        }

        @include mobile {
            width: 100%;
            text-align: center;
        }

        a {
            @include mobile {
                font-size: 14px;
                font-weight: 700;
            }

            &:first-child {
                margin-inline-end: 40px;

                @include mobile {
                    margin-inline-end: 15px;
                }
            }
        }
    }

    &.CheckoutPage {
        .Newsletter-NewsletterBlockWrapper {
            display: none;
        }

        .Footer-CopyrightContentWrapper {
            @include desktop-wide-1440 {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 60px;
                margin-block-start: 0px;
            }

            @include narrow-desktop {
                padding-inline-start: 32px;
                max-width: 1350px;
                padding-inline-end: 32px;
                padding-block-end: 40px;
                margin-block-start: 0px;
            }

            @include tablet {
                padding-inline-start: 32px;
                max-width: 100%;
                padding-inline-end: 32px;
                padding-block-end: 30px;
                margin-block-start: 40px;
            }
        }
    }
}
