/* extend Sidea - Banner ritira in store Click & Collect */

.BannerClickandCollect {
    width: calc(100% - 20.5%);
    margin: 0 auto;
    margin-block-end: 9rem;

    @include narrow-desktop {
        width: calc(100% - 15%);
    }

    @include ultra-narrow-desktop {
        width: calc(100% - 5.6%);
    }

    @include tablet {
        width: calc(100% - 5.6%);
    }

    @include mobile {
        width: calc(100% + 36px);
        margin-block-end: 50px;
        margin-inline: -18px;
    }

    .pagebuilder-column-group {
        gap: 10px;

        @include mobile {
            flex-direction: column;
            gap: 0;
        }
    }

    .pagebuilder-column {
        @include mobile {
            width: 100% !important;
        }
    }

    &-BloccoTesti {
        background-image: var(--banner-clicl-and-collect-sfondo);
        background-size: cover !important;
        justify-content: center !important;
        padding-inline: 5.5%;

        @include narrow-desktop {
            padding-inline: 4%;
        }

        @include ultra-narrow-desktop {
            padding-inline: 4%;
        }
    
        @include tablet {
            padding-inline: 4%;
        }

        @include mobile {
            padding: 27px 36px;
            text-align: center;
        }

        .BloccoClickandCollect-Title {
            p {
                br {
                    @include mobile {
                        content: "";
    
                        &::after {
                            content: " ";
                        }
                    }
                }
            }

            & + div {
                p {
                    @include mobile {
                        font-size: 13px;
                    }
                }
            }
        }

        .pagebuilder-button-primary {
            min-width: 207px;
            margin-block-start: 10px;

            @include mobile {
                min-width: 270px;
            }
        }
    }
}
