:root {
    --product-labels-width: 84px;

    @include narrow-desktop {
        --product-labels-width: 70px;
    }

    @include ultra-narrow-desktop {
        --product-labels-width: 65px;
    }

    @include tablet {
        --product-labels-width: 65px;
    }

    @include mobile {
        --product-labels-width: 55px;
    }
}

.Label {
    position: absolute;
    z-index: 20;
    inset-block-start: 17px;
    inset-inline-start: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: var(--product-labels-width);
    height: calc(var(--product-labels-width) + 4px);
    background: var(--labels-background) center center;
    background-size: contain;
    background-repeat: no-repeat;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    font-style: italic;
    text-transform: none;
    font-family: $font-muli;
    color: $white;

    @mixin position-narrow {
        inset-block-start: 13px;
        inset-inline-start: 18px;
    }

    @include narrow-desktop {
        @include position-narrow;
    }

    @include ultra-narrow-desktop {
        @include position-narrow;
    }

    @include tablet {
        @include position-narrow;
    }

    @include mobile {
        inset-block-start: 6px;
        inset-inline-start: 8px;
    }

    span {
        color: $white;
        font-size: 2.5rem;
        letter-spacing: -0.25rem;
        text-transform: lowercase;
        margin-inline-start: -7px;

        @include mobile {
            font-size: 1.8rem;
            letter-spacing: -0.2rem;
            margin-inline-start: -4px;
        }
    }

    &.New {
        &.small {
            inset-block-start: calc(var(--product-labels-width) + 25px);
            height: 25px;
            padding-block-start: 0;
            border-top: none;
            background: none;
            color: $black;

            @include mobile {
                inset-block-start: calc(var(--product-labels-width) + 5px);  
            }

            span {
                color: $default-primary-base-color;
                font-size: 3rem;
                letter-spacing: -0.18rem;

                @include mobile {
                    font-size: 1.8rem;  
                }
            }
        }
    }

    &.Sale {
        span {
            font-size: 3.3rem;

            @include mobile {
                font-size: 2.1rem;
            }
        }
    }
}
