/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** extends style of component */

:root {
    --newsletter-subscription-button-background: var(--link-color);
}

.ContentWrapper {
    &.Newsletter-NewsletterContent {
        display: flex;
        background-color: #00ACE2;
        max-width: 100%;
        padding-block-start: 50px;
        padding-block-end: 30px;
        padding-inline: 10%;

        @include ultra-narrow-desktop {
            padding-inline: 4%;
        }

        @include tablet {
            padding-inline: 4%;
        }

        @include mobile {
            flex-direction: column;
            padding-inline: 18px;
            padding-block: 45px;
            margin-block-start: 50px;
        }

        h2 {
            margin-block-end: 25px;

            @include mobile {
                line-height: 1.3;
            }
            
            span {
                border-bottom: 1px solid $default-neutral-base-color;
            }
        }

        .Newsletter-Title {
            width: 50%;
            padding-inline-end: 20%;

            @include desktop-wide-1600 {
                padding-inline-end: 50px;
            }

            @include ultra-narrow-desktop {
                padding-inline-end: 30px;
            }

            @include tablet {
                padding-inline-end: 30px;
            }

            @include mobile {
                width: 100%;
                padding-inline-end: 0;
                text-align: center;
            }
            
            .h1 {
                letter-spacing: -0.07rem;
            }
        }

        .NewsletterSubscription {
            width: 50%;
            max-width: 686px;

            @include mobile {
                width: 100%;
            }

            h4 {
                margin-block-end: 8px;

                @include mobile {
                    text-align: center;
                }
            }

            .FieldForm {
                &-Body {
                    display: flex;
                    gap: 10px;

                    @include mobile {
                        flex-direction: column;
                        gap: 0;
                    }
                }

                &-Fieldset {
                    .FieldGroup_isValid {
                        padding-inline-start: 0;
                        border-left: none;
                    }

                    .Field {
                        width: 100%;
                        margin-block-start: 0;
        
                        input {
                            &[type=email] {
                                height: 50px;
                                background-color: $white;
                                border: none;
                                padding-inline: 20px;
                                font-size: 1.4rem;
                                font-weight: 400;

                                @include mobile {
                                    padding: 13px 19px 13px;
                                }

                                &::placeholder {
                                    color: $black;
                                    opacity: 1;
                                    font-weight: 400;
                                }
                            }

                            &[type=checkbox] {
                                padding: 0;
                                border: 1px solid $default-neutral-base-color;
                                border-radius: 0;

                                + .input-control {
                                    border-radius: 0px !important;
                                    max-width: var(--checkbox-width);
                                    width: 24px;

                                    &::after {
                                        box-shadow: none;
                                        content: '';
                                        height: 8px;
                                        width: 8px;
                                        inset-block-start: 4px;
                                        inset-inline-start: 4px;
                                        transform: none;
                                        border-radius: 0px;
                                        transition: none;
                                    }
                                }

                                &:hover:not(:disabled) + .input-control {
                                    --box-color: #fff;

                                    border: 1px solid $default-primary-dark-color;
                                    margin-inline-end: 11px;
                                }
                            }
                        }

                        &.Field_type_checkbox {
                            margin-block-start: 20px;
                            font-size: 10px;

                            .Field-CheckboxLabel,
                            .Field-RadioLabel {
                                align-items: flex-start;

                                .label-text {
                                    display: inline-block;
                                    width: calc(100% - 30px);
                                    text-align: start;
                                }
    
                                @include narrow-desktop {
                                    width: 140%;
                                }

                                @include tablet {
                                    width: 140%;
                                }

                                @include mobile {
                                    text-align: start;
                                }

                                &:not([class*='_isDisabled']):hover {
                                    color: $black;
                                }
                            }
                        }
                    }
                }
        
                &-Fields {
                    width: calc(100% - 194px);
                    margin-block-end: 10px;

                    @include mobile {
                        width: 100%;
                    }
                }
            }
        
            .Button {
                margin-block-start: 0;
                width: 194px;

                @include mobile {
                    width: 100%;
                    max-width: 350px;
                    display: block;
                    margin: 0 auto;
                    margin-inline-start: auto;
                    margin-block-start: 20px;
                }
            }
        }
    }
}



