/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LavoraConNoi {
    .CmsBlock {
        &-Wrapper {
            h2 {
                font-size: 3.8rem;
                font-weight: bold;
                background-color: $default-primary-dark-color;
                text-align: center; 
                max-width: 1230px;
                width: 100%;
                margin-block-start: -70px;
                margin-block-end: 50px;
                margin-inline: auto;
                padding: 2%;

                @include desktop-wide-1440 {
                    max-width: 900px;
                    margin-block-start: -57px;
                }

                @include narrow-desktop {
                    max-width: 900px;
                    margin-block-start: -57px;
                }

                @include ultra-narrow-desktop {
                    max-width: 900px;
                    margin-block-start: -44px;
                }

                @include tablet {
                    max-width: 900px;
                    margin-block-start: -44px;
                }

                @include mobile {
                    height: auto;
                    font-size: 2.7rem;
                    margin-block-start: -10px;
                    margin-block-end: 30px;
                    padding: 20px;
                }
            }

            h5 {
                text-align: center;
                font-size: 2rem;
                margin-block-end: 20px;

                @include mobile {
                    font-size: 1.8rem;
                    padding-inline: 10px;
                    margin-block-end: 10px;
                }
            }

            div[data-content-type="text"] {
                max-width: 755px;
                width: 100%;
                margin-inline: auto;

                p {
                    font-size: 1.6rem;

                    @include mobile {
                        padding-inline: 18px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
