/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ContactForm {
    &-Heading {
        margin-block-end: 47px;

        @include mobile {
            margin-block-end: 27px; 
        }

        h5 {
            font-size: 2rem;
            line-height: 1.4;
            color: $default-secondary-dark-color;
            font-weight: bold;
            max-width: 469px;
            margin-block-start: 0;
            margin-block-end: 5px;

            @include mobile {
                font-size: 2.1rem;
                margin-block-end: 15px;
                margin-block-start: 20px;
            }
        }

        p {
            font-size: 1.6rem;
            font-weight: normal;

            @include mobile {
                font-size: 1.4rem;
            }
        }
    }

    .FieldForm-Fields {
        display: flex;
        gap: 20px;

        @include mobile {
            flex-direction: column;
            gap: 10px;
        }

        .FieldForm-FirstColumn,
        .FieldForm-SecondColumn {
            width: 50%;

            @include mobile {
                width: 100%;
            }
        }

        .FieldForm-RequiredNote {
            display: none;
            font-size: 10px;
            margin-block-start: 10px;
            color: $default-secondary-dark-color;

            &.visibleDesktop {
                @include desktop {
                    display: block;
                }
            }

            &.visibleMobile {
                @include mobile {
                    margin-block-start: 0;
                    display: block;
                    margin-block-end: 20px;
                }
            }
        }
    }

    textarea {
        width: 100%;
        border: 1px solid #CCCCCC;
        min-height: 269px;

        @include mobile {
            min-height: 100px;
        }
    }

    .Field-Wrapper_type_textarea {
        min-height: 100%;
        margin-block-end: 20px;

        @include tablet {
            margin-block-start: 24px;
        }

        @include mobile {
            min-height: unset;
        }
    }

    .Field-Wrapper {
        width: 100%;
        max-width: 720px;
        height: 60px;

        @include narrow-tablet {
            max-width: 100%;
        }

        @include mobile {
            height: auto;
        }

        .Field_type_text, .Field_type_tel, .Field_type_email {
            margin-block-start: 10px;
        }
    }

    .Field [type='checkbox'] + .input-control {
        border: 1px solid #666666;
    }

    .Field.Field_type_checkbox {
        font-size: 1.2rem;
        color: $default-secondary-dark-color;
    }

    .bottom-form {
        margin-block-start: 25px;
        max-width: 450px;
    }

    .p-0 {
        padding: 0;
    }

    .input-control {
        width: 48px !important; /* stylelint-disable-line declaration-no-important */
    }

    .Field-CheckboxLabel {
        align-items: flex-start;
    }

    .Button {
        width: 294px;
        height: 50px;

        @include ultra-narrow-desktop {
            width: 100%;
        }

        @include tablet {
            width: 100%;
        }
    }

    input, textarea, select {
        border: 1px solid $default-secondary-dark-color;
    }

    select {
        height: 60px;
    }

    .Field-Wrapper:first-of-type .Field {
        margin-block-start: 0;
    }

    label {
        display: none;
    }
}
