/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.LavoraConNoiForm {
    margin-block-start: 80px;
    padding-inline: 19.5%;

    @include ultra-narrow-desktop {
        padding-inline: 12.5%;
    }

    @include tablet {
        padding-inline: 12.5%;
    }

    @include mobile {
        margin-block-start: 0;
        padding-inline: 18px;
    }

    &-Heading {
        h5 {
            @include mobile {
                font-size: 1.8rem;
                text-align: center;
            }
        }
    }

    .Form {
        .Field-LabelContainer {
            display: none;
        }

        .Field {
            margin-block-start: 10px;
        }

        .FieldForm {
            &-Fields {
                display: flex;
                gap: 50px;

                @include mobile {
                    flex-direction: column;
                    gap: 10px;
                }
            }

            &-FirstColumn,
            &-SecondColumn {
                width: 50%;

                @include mobile {
                    width: 100%;
                }
            }

            &-RequiredNote {
                font-size: 12px;
                margin-block-start: 10px;
            }

            &-Format {
                margin-block: 20px;
                font-weight: bold;
                font-size: 1.6rem;
            }

            &-Note {
                font-weight: normal;
                font-size: 1.6rem;
                margin-block: 20px;
                padding-block-end: 15px;
                border-block-end: 1px solid #e2e2e2;
            }
        }

        .Field_type_select {
            height: 60px;

            select, .FieldSelect-Clickable {
                height: 60px;
            }
        }

        .Field [type='checkbox'] + .input-control {
            border: 1px solid #666666;
        }
    
        .Field.Field_type_checkbox {
            font-size: 1.2rem;
            color: $default-secondary-dark-color;
        }
    
        .bottom-form {
            margin-block-start: 25px;
            max-width: 450px;
        }
    
        .p-0 {
            padding: 0;
        }
    
        .input-control {
            width: 48px !important; /* stylelint-disable-line declaration-no-important */
        }
    
        .Field-CheckboxLabel {
            align-items: flex-start;
        }

        .Button {
            margin-block-start: 50px;
            width: 260px;
            height: 50px;
            display: flex;
            margin-inline-start: auto;

            @include mobile {
                width: 100%;
                max-width: 370px;
                margin: 0 auto;
                margin-block-start: 30px;
            }
        }

        .amrma-attach-file {
            .LavoraConNoiForm-File {
                display: none;
            }

            .amrma-label {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                font-size: 1.4rem;
                cursor: pointer;
                color: $black;
                transition: color .3s ease;
                border: 1px solid $black;
                height: 60px;
                max-width: 150px;
                width: 100%;
                min-width: unset;

                @include desktop-wide-1440 {
                    height: 50px;
                }

                @include narrow-desktop {
                    height: 50px;
                }

                @include tablet {
                    height: 50px;
                }

                @include mobile {
                    height: 50px;
                }
            }
    
            .amrma-label:hover {
                text-decoration: underline;
                color: $default-primary-dark-color;
            }
    
            .amrma-file {
                margin: 5px 0;
                padding-inline-start: 20px;
                color: #1979c3;
            }
        }
    }
}
