/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';


/**
 * Store Finder compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

.StoreCard {
    &-Circles {
        display: inline-block;
        line-height: 1;
        margin: 0 5px 0 0;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        background-color: var(--secondary-base-color);
        font-size: 1.2rem;
        color: #fff;
        font-weight: 600;
        text-align: center;
        padding-block-start: 7px;

        &_isActive {
            background-color: var(--primary-base-color);
        }
    }

    &-Details {
        display: flex;
        grid-gap: 40px;
        cursor: pointer;

        @include desktop-wide-1440 {
            grid-gap: 30px;
            padding-inline-end: 15px;
        }

        @include ultra-narrow-desktop {
            grid-gap: 20px;
            padding-inline-end: 10px;
        }

        @include tablet {
            grid-gap: 20px;
            padding-inline-end: 10px;
        }

        @include mobile {
            grid-gap: 18px;
            width: 100%;
        }
    }

    &-StoreImage {
        width: 100%;
        max-width: 130px;
        min-width: 130px;

        @include tablet {
            max-width: 120px;
            min-width: 120px;
        }

        @include mobile {
            max-width: 120px;
            min-width: 100px;
        }
    }

    &-Title {
        margin-block-start: 0;
        margin-block-end: 15px;
        font-size: 2.4rem;
        font-weight: bold;
        color: $black;

        @include desktop-wide-1440 {
            font-size: 2rem;
        }

        @include narrow-desktop {
            font-size: 2rem;
            margin-block: 5px;
        }

        @include ultra-narrow-desktop {
            font-size: 1.8rem;
            margin-block: 5px;
        }

        @include tablet {
            font-size: 2rem;
            margin-block: 5px;
        }

        @include mobile {
            font-size: 1.6rem;
            margin-block-end: 0;
            text-align: left;
        }
    }

    &-Content {
        margin-block-end: 27px;

        @include tablet {
            margin-block-end: 15px;
        }

        @include mobile {
            margin-block-end: 19px;
        }

        p {
            margin: 0px;
            padding: 0px;
            font-size: 2rem;
            font-weight: normal;
            font-family: $font-base;
            color: $black;

            @include narrow-desktop {
                font-size: 1.8rem;
            }

            @include ultra-narrow-desktop {
                font-size: 1.6rem;
            }

            @include tablet {
                font-size: 1.6rem;
            }

            @include mobile {
                font-size: 1.6rem;
                text-align: left;
            }
        }
    }

    &-ContentField {
        margin-block-end: 5px;
        
        a {
            font-size: 1.6rem !important;
            color: #3A3939 !important;
            font-family: $font-base !important;
            font-style: normal !important;
        } 
    }

    &-Navigation {
        margin-block-start: 12px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        @include tablet {
            margin-block-start: 0;
        }
    }

    &-ButtonShop {
        background-color: $black;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0px;
        font-size: 1.4rem;
        font-family: $font-muli;
        font-weight: bold;
        width: 250px;
        max-width: 90%;
        height: 50px;

        &:hover {
            background-color: $default-secondary-dark-color;
            color: $white;
        }

        @include tablet {
            width: 185px;
            height: 35px;
            padding: 0;
            line-height: 35px;
        }

        @include mobile {
            width: 180px;
            height: 35px;
            padding: 0;
            line-height: 35px;
        }
    }
}
