/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MembershipPopUp {
    &-Container {
        background-color: transparent;
        inset-inline: 0;
        inset-block: 0;
        inset-inline-start: auto;
        inset-inline-end: 35px;
        inset-block-start: auto;
        inset-block-end: 80px;
        transform: translateY(100%); /* Inizialmente fuori dalla visuale in basso */
        opacity: 0; /* Inizialmente invisibile */
        transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out; /* Effetto di transizione */

        &_isVisible {
            width: 100%;
            max-width: 360px;
            height: 600px;
            display: block;
            transform: translateY(0); /* Si sposta nella posizione desiderata */
            opacity: 1; /* Diventa visibile */

            @include mobile {
                max-width: 100vw;
                height: 100%;
                inset-block-start: 0;
                inset-block-end: auto;
                inset-inline-start: 0;
                inset-inline-end: 0;
                padding-block-start: 56px;
            }
        }

        &::before {
            position: relative;
            content: var(--logo-popup-membership);
            margin-inline-start: 42%;
            inset-block-start: 30px;
            z-index: 99;

            @include mobile {
                display: none;
            }
        }

        .Popup {
            &-CloseBtn {
                @include mobile {
                    position: absolute;
                    inset-block-start: 15px;
                    inset-inline-end: 20px;
                }
            }

            &-Header {
                display: flex;

                .CloseIcon {
                    width: 15px;
                    height: 15px;

                    @include mobile {
                        stroke: $white;
                        fill: $white;
                    } 

                    &:hover {
                        stroke: $white;
                        fill: $white;
                    }
                }
            }

            &-Content { 
                min-width: unset;
                width: 100%;
                height: 100%;
                max-height: 100%;
                padding: 0;
                background-image: var(--sfondo-popup-membership);
                background-repeat: no-repeat;

                @include mobile {
                    background-size: cover;
                }
            }
        }
    }

    &-Header {
        color: $default-primary-base-color;
        padding-inline-start: 15px;
        margin-block-start: -20px;
        margin-block-end: 10px;

        p {
            color: $default-primary-base-color;
            font-size: 3.2rem;
            font-weight: 500;
            text-transform: uppercase; 
            margin: 0;
            
            &:first-child {
                font-size: 1.8rem;
                margin-block-start: 6px;
            }
        }
    }

    &-Text {
        padding-inline-start: 15px;
        margin-block-end: 15px;

        p {
            color: $white;
            margin: 0;
            font-size: 1.4rem;
            font-weight: normal;
            padding-inline-end: 40px;

            &:first-child {
                font-size: 2.1rem;
                font-weight: bold;
                padding-inline-end: 65px;
                margin-block-end: 10px;
            }
        }
    }
}
