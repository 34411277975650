/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.SearchItem {
    &-CustomAttribute {
        color: $black;
        font-size: 10px;
        font-weight: 700;
        opacity: 1;
    }

    &-Content {
        .ProductPrice {
            margin-block-start: 3px;
            font-size: 1.3rem;
            font-weight: 700;

            &-PriceValue {
                line-height: 1;
                margin-block-start: 0;
            }

            & del {
                line-height: 1;
            }
        }
    }
}
