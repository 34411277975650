/** file di customizzazione sidea (nota: i file di stile dei singoli componenti hanno priorità)  */
/* stylelint-disable declaration-no-important */

/* Variabili globali */

:root {
    --newsletter-icon: url('images/ico-mail.png');
    --icon-map: url('images/ico-map.png');
    --arrow-next: url('images/arrow-next.svg');
    --arrow-prev: url('images/arrow-prev.svg');
    --loghi-secure: url('images/loghi-ecomm.png');
    --ico-sped: url('images/ico-sped.svg');
    --ico-shopper: url('images/ico-shopper.svg');
    --icon-mail: url('images/ico-mail.svg');
    --icon-phone: url('images/icon-phone.png');
    --click-collect-image: url('images/click-collect-banner.png');
    --click-collect-image-mobile: url('images/click-collect-banner-mobile.jpg');
    --banner-clicl-and-collect-sfondo: url('images/sfondo-click-collect.jpg');
    --logo-cart: url('images/banner-logo-cart.svg');
    --popup-cart: url('images/popup-spedizione-cart.svg');
    --fidelity-image: url('images/fidelity-card-2.png');
    --fidelity-people-mobile: url('images/fidelity-people.jpg');
    --click-collect-buste: url('images/click-buste.png');
    --uomo-corsa: url('images/uomo-corsa.png');
    --uomo-corsa-verde: url('images/uomo-corsa-2.png');
    --background-fidelity-reserved-area: url('images/backreservedfidelity.png');
    --ionic-search: url('images/ionic-md-locate.png');
    --omino-resi: url('images/omino-corsa.svg');
    --omino-resi-disabled: url('images/omino-gray.svg');
    --stampante: url('images/stampante.svg');
    --facebook-icon: url('images/facebook.png');
    --google-icon: url('images/google.png');
    --twitter-icon: url('images/twitter.png');
    --linkedin-icon: url('images/linkedin.png');
    --sfondo-menu-account: url('images/sfondo-menu-account.png');
    --icona_uomo_corsa: url('images/uomo-corsa_1.png');
    --button-arrows: url('images/button-arrows.svg');
    --icone-carte-footer: url('images/ico-cards.png');
    --icone-carte-footer-mobile: url('images/ico-cards-mobile.png');
    --sfondo-popup-membership: url('images/sfondo-member.jpg');
    --logo-popup-membership: url('images/logo-membership.png');
    --ico-freccia-destra: url('images/ico-arrow-right.svg');
    --labels-background: url('images/labels-background.png');
    --ico-frecce-grandi: url('images/frecce-grandi.png');
    --trustpilot-logo: url('images/trustpilot_logo.png');
    --icona-prodotto-non-disponibile: url('images/Icona_X.svg');
}

/* generali */

html {
    scroll-behavior: smooth;

    @include desktop-wide-1440 {
        font-size: 9px;
    }

    @include ultra-narrow-desktop {
        font-size: 8px;
    }

    @include tablet {
        font-size: 8px;
    }

    @include mobile {
        font-size: 10px;
    }
}

body iframe:last-of-type {
    display: none;
}

body {
    overflow-x: hidden;
    margin-block-start: 0 !important;
    
    main.CmsPage {
        iframe {
            display: block !important;
        }
    }

    &.noscroll {
        overflow: hidden;
    }
}

#whatsapp-link {
    inset-block-end: 12%;
    inset-inline-end: 9px;
}

h1 {
    &.evidence {
        font-size: 7.2rem;
        font-weight: 600;

        @include desktop-wide-1440 {
            font-size: 6.5rem; 
        }

        @include narrow-desktop {
            font-size: 6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 5rem;
        }

        @include tablet {
            font-size: 5rem;
        }
    }

    &.light {
        font-weight: 300;
    }

    &.var-green {
        color: $default-primary-base-color;
        font-weight: bold;
        text-transform: none;
    }
}

h2 {
    &.evidence {
        font-size: 3.9rem;

        @include narrow-desktop {
            font-size: 3.3rem;
        }

        @include tablet {
            font-size: 3rem;
        }

        @include narrow-tablet {
            font-size: 3rem;
        }
    }

    &.text-up {
        text-transform: uppercase;
    }
}

h3 {
    a {
        font-family: $font-muli;
    }
}

.var-green {
    color: $default-primary-base-color;
}

.var-fux {
    color: $default-secondary-base-color;
}

.section-title {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);

    @include desktop-wide-1440 {
        font-size: var(--h1-font-size-wide);
        line-height: var(--h1-line-height-wide);
    }

    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h1-font-size-tablet);
        line-height: var(--h1-line-height-tablet);
        margin-block-end: 10px;
    }

    @include mobile {
        font-size: var(--h1-font-size-mobile);
        padding-block-end: 6px;
        margin-block-end: 8px;
        margin-block-start: 0;
    }
}

.evidenza,
.evidenza h2 {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-line-height);

    @include desktop-wide-1440 {
        font-size: var(--h1-font-size-wide);
        line-height: var(--h1-line-height-wide);
    }
    
    @include narrow-desktop {
        font-size: var(--h1-font-size-narrow);
        line-height: var(--h1-line-height-narrow);
    }

    @include tablet {
        font-size: var(--h1-font-size-tablet);
        line-height: var(--h1-line-height-tablet);
    }

    @include mobile {
        font-size: var(--h2-font-size);
    }
}

.txt-light {
    font-weight: 300;
}

.no-pad {
    padding-inline: 0 !important;
}

.p24, .p24 p {
    font-size: 2.4rem;
    line-height: 1.4;
    font-weight: 300;

    @include desktop-wide-1440 {
        font-size: 2.1rem;
    }

    @include narrow-desktop {
        font-size: 2.1rem;
    }

    @include ultra-narrow-desktop {
        font-size: 1.8rem;
    }

    @include tablet {
        font-size: 1.8rem;
    }

    @include narrow-tablet {
        font-size: 1.6rem;
    }
}

.p18,
.p18 p {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.45;
}

.p16,
.p16 p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.55;
    color: #000000;
}

.p14, .p14 p {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
}

.p12, .p12 p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;

    @include mobile {
        font-size: 12px;
    }
}

.p10, .p10 p {
    font-size: 10px;
}

a {
    font-weight: 500;
}

.link-primary {
    color: $default-primary-base-color;
    font-weight: 500;

    &:hover {
        color: $default-neutral-base-color;
    }
}

.image-nofull,
.image-nofull img {
    width: auto;
    height: auto;
}

.mb-1 {
    margin-block-end: 10px !important;
}

.mb-2 {
    margin-block-end: 20px !important;
}

.mb-3 {
    margin-block-end: 30px !important;
}

.mb-4 {
    margin-block-end: 40px !important;
}

.mb-5 {
    margin-block-end: 50px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 40px !important;
    }

    @include tablet {
        margin-block-end: 40px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-6 {
    margin-block-end: 60px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 50px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-7 {
    margin-block-end: 70px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 50px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-8 {
    margin-block-end: 80px !important;

    @include ultra-narrow-desktop {
        margin-block-end: 60px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }
    
    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-9 {
    margin-block-end: 90px !important;

    @include desktop-wide-1600 {
        margin-block-end: 70px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 80px !important;
    }

    @include tablet {
        margin-block-end: 50px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-10 {
    margin-block-end: 100px !important;

    @include desktop-wide-1600 {
        margin-block-end: 80px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 80px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 50px !important;
    }
}

.mb-11 {
    margin-block-end: 110px !important;

    @include desktop-wide-1600 {
        margin-block-end: 90px !important;
    }

    @include tablet {
        margin-block-end: 60px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-14 {
    margin-block-end: 140px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include ultra-narrow-desktop {
        margin-block-end: 90px !important;
    }

    @include tablet {
        margin-block-end: 80px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-15 {
    margin-block-end: 150px !important;

    @include desktop-wide-1600 {
        margin-block-end: 100px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-16 {
    margin-block-end: 160px !important;

    @include desktop-wide-1600 {
        margin-block-end: 110px !important;
    }

    @include tablet {
        margin-block-end: 90px !important;
    }

    @include mobile {
        margin-block-end: 60px !important;
    }
}

.mb-20 {
    margin-block-end: 200px !important;

    @include desktop-wide-1440 {
        margin-block-end: 120px !important;
    }

    @include narrow-desktop {
        margin-block-end: 100px !important;
    }
    
    @include tablet {
        margin-block-end: 100px !important;
    }
}

.Header {
    &-MinicartButtonWrapper span::before {
        content: url('images/icon-cart.svg');
        vertical-align: middle;
        margin-inline-end: 6px;
        
        @include tablet {
            transform: scale(0.75);
            display: inline-block;
            margin-inline-end: 2px;
        }
    }
}

.NotificationList {
    @include mobile {
        inset-block-start: 76px;
        z-index: 9999;
    }
}

.product-showcase {
    &.prodotti-novita:has(.NewProducts-EmptyNew) {
        display: none !important;
    }

    @include mobile {
        padding-block-end: 50px;
        margin-block-end: 50px !important;
    }

    h2.section-title {
        text-align: start;
        margin-block-end: 0;

        @include mobile {
            font-size: 3rem;
            text-align: center;
        }
    }

    h4 {
        font-weight: 400;
        text-align: center;

        @include mobile {
            & + div {
                position: absolute;
                inset-block-end: -53px;
                width: calc(100% - 18px);
                text-align: center !important;
            }       
        }

        & + div {
            text-align: end;
        }
    }

    .pagebuilder-button-link {
        font-family: $font-muli;
        font-weight: 600;
        font-size: 1.6rem;
        padding-block-end: 4px;
        border-bottom: 1px solid $black;

        span {
            font-family: $font-muli;
        }

        @include mobile {
            @include button;

            display: block;
            position: absolute;
            inset-block-end: -470px;
            inset-block-start: auto;
            inset-inline-start: -270px;
            min-width: 198px;
            height: 43px;
            max-width: 324px;
            text-align: center;
            box-shadow: 8px 9px #DEDEDE;
            font-weight: 400;
            font-size: 1.6rem;
            text-transform: none;
        }
    }

    .container {
        &.product-carousel {
            @include mobile {
                padding-inline: 0;
                width: 235%;
                max-width: unset;

                .slick-next,
                .slick-prev {
                    display: none !important;
                }

                .slick-slide {
                    padding-inline-end: 5px;
                }
            }
        }
    }

    .Label {
        @include mobile {
            padding-block-start: 9px !important;

            span {
                font-size: 1.3rem;
            }
        }
    }
}

.box-genere {
    margin-block-end: 80px;

    @include mobile {
        margin-block-end: 40px;
        padding-inline-start: 19px;
    }

    .pagebuilder-column-group {
        display: flex;
        gap: 10px;

        @include mobile {
            flex-direction: row;
            overflow-x: scroll;
            gap: 5px;
        }

        .pagebuilder-column {
            @include mobile {
                min-width: 234px !important;
            }

            &:hover {
                img {
                    opacity: 0.8;
                }
            }
        }

        h3 {
            margin-block: 12px;

            a {
                @include mobile {
                    font-size: 1.6rem;
                    font-weight: bold;
                }
            }
        }
    }
}

.best-sellers {
    .ProductListWidget {
        padding-block-end: 0;
    }

    .best-seller-box {
        padding-block-start: 25px;
        padding-inline-end: 10px;
    }    
}

.banner-promo-wrapper {
    padding-inline: 0 !important;
    width: 100% !important;
    margin-block-end: 0 !important;

    @include mobile {
        display: none !important;
    }

    &.mobile {
        display: none !important;

        @include mobile {
            display: flex !important;
        }
    }
}

.banner-vantaggi {
    background-color: $default-neutral-extralight-color;
    max-width: var(--content-wrapper-width);
    margin: 0 auto;
    margin-block-start: 110px;
    margin-block-end: 60px;
    padding-inline: 7%;

    @include ultra-narrow-desktop {
        margin-block-start: 50px;
        margin-block-end: 30px;
        padding-inline: 4%;
        max-width: 95vw;
    }

    @include tablet {
        margin-block-start: 40px;
        margin-block-end: 30px;
        padding-inline: 4%;
        max-width: 95vw;
    }

    @include mobile {
        margin-block-start: 25px;
        margin-block-end: 25px;
        max-width: 100vw;
    }

    .pagebuilder-column {
        justify-content: center;

        &:first-child {
            img {
                @include mobile {
                    transform: scale(0.7);
                    margin-block-end: -10px;
                }
            }
        }

        &:nth-child(2) {
            &::after, &::before {
                content: '';
                position: absolute;
                inset-block-start: 14px;
                height: 58px;
                width: 1px;
                display: block;
                border-inline-start: 1px solid #fff;
            }

            &::before {
                inset-inline-end: 0;
            } 
        }

        &:last-child {
            img {
                @include mobile {
                    transform: scale(0.5);
                    margin-block-end: -8px;
                }
            }
        }
    }

    h3 {
        @include ultra-narrow-desktop {
            font-size: 2rem;
        }

        @include tablet {
            font-size: 1.8rem;
        }

        @include mobile {
            font-size: 1.2rem;
            text-align: center !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 1.1;
            font-weight: 500;
            margin-block-end: 10px;
            margin-block-start: -5px;
            padding-inline: calc(100% - 95px);
        }
    }

    img {
        display: inline-block;
        vertical-align: middle;
        margin-inline-end: 10px;
        margin-block-end: 10px;

        @include ultra-narrow-desktop {
            transform: scale(0.9);
        }

        @include tablet {
            transform: scale(0.8);
        }

        @include mobile {
            transform: scale(0.6);
            display: block;
            margin-inline-end: 0;
            margin-inline-start: 0;
            margin-block-end: 0;
        }
    }
}
