/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

.Breadcrumb {
    display: inline-block;
    vertical-align: top;
    font-size: 13px;
    line-height: 1;
    margin-block-end: 0;
    font-weight: 500;
    padding-inline-end: 0;

    &:last-child {
        margin-block-end: 0;

        a {
            color: --breadcrumbs-active-color;
        }

        :link {
            pointer-events: none;
        }

        &::after {
            content: none;
        }
    }

    &::after {
        content: "-";
        margin-inline: 3px;
    }

    &-Link {
        color: var(--breadcrumbs-color);
        display: inline-block;
        font-weight: 500;
        font-size: 13px;

        a {
            color: var(--breadcrumbs-color)
        }

        &:hover {
            color: var(--breadcrumbs-active-color);
        }
    }

    &-Page {
        font-weight: 700;
        color: $black;
    }
}
