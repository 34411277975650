/** override sidea  */
/* stylelint-disable declaration-no-important */

:root {
    --secondary-button-color: $white;
    --default-button-padding: 20px;
    --default-button-radius: 0;
    --default-button-height: 50px;
    --button-max-width: 270px;

    @include mobile {
        --default-button-height: 50px;
        --button-max-width: 270px;
    }
    
    --default-text-decoration: none;
    --button-border-width: 1px;
    
    // Primary button
    --button-background: var(--imported_buttons_background_color, #{$black});
    --button-border: var(--imported_buttons_border_color, #{$black});
    --button-border-radius: var(--imported_buttons_border_radius, var(--default-button-radius));
    --button-color: var(--imported_buttons_color, #{$white});
    --button-height: var(--imported_buttons_height, var(--default-button-height));
    --button-padding: var(--imported_buttons_padding, var(--default-button-padding));
    --button-text-decoration: var(--imported_buttons_text_decoration, var(--default-text-decoration));
    // Primary button (Hover)
    --button-hover-background: var(--imported_hoverButtons_background_color, #{$default-secondary-light-color});
    --button-hover-border: var(--imported_hoverButtons_border_color, #{$default-secondary-light-color});
    --button-hover-border-radius: var(--imported_hoverButtons_border_radius, var(--default-button-radius));
    --button-hover-color: var(--imported_hoverButtons_color, #{$white});
    --button-hover-height: var(--imported_hoverButtons_height, var(--default-button-height));
    --button-hover-padding: var(--imported_hoverButtons_padding, var(--default-button-padding));
    --button-hover-decoration: var(--imported_hoverButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hollow)
    --hollow-button-background: var(--imported_hollowButtons_background_color, var(--secondary-button-color));
    --hollow-button-border: var(--imported_hollowButtons_border_color, #{$black});
    --hollow-button-border-radius: var(--imported_hollowButtons_border_radius, var(--default-button-radius));
    --hollow-button-color: var(--imported_hollowButtons_color, #{$black});
    --hollow-button-height: var(--imported_hollowButtons_height, var(--default-button-height));
    --hollow-button-padding: var(--imported_hollowButtons_padding, var(--default-button-padding));
    --hollow-button-text-decoration: var(--imported_hollowButtons_text_decoration, var(--default-text-decoration));
    // Secondary button (Hover)
    --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, #E8E8E8);
    --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, #{$black});
    --hollow-button-hover-border-radius: var(--imported_hollowHoverButtons_border_radius, var(--default-button-radius));
    --hollow-button-hover-color: var(--imported_hollowHoverButtons_color, #{$black});
    --hollow-button-hover-height: var(--imported_hollowHoverButtons_height, var(--default-button-height));
    --hollow-button-hover-padding: var(--imported_hollowHoverButtons_padding, var(--default-button-padding));
    --hollow-button-hover-text-decoration: var(--imported_hollowHoverButtons_text_decoration, var(--default-text-decoration));
}

.pagebuilder-button-primary,
.pagebuilder-button-secondary {
    @include button; // importa il mixin nel file _button in abstract

    width: 100%;
    max-width: var(--button-max-width);

    span {
        &.link_text {
            font-size: 1.4rem;
        }
    }
}

.pagebuilder-button-secondary {
    color: var(--hollow-button-color) !important;
    border-color: var(--hollow-button-border);
    background-color: var(--hollow-button-background);

    span {
        color: var(--hollow-button-color);
    }

    &:hover {
        background-color: var(--hollow-button-hover-border);
        border-color: var(--hollow-button-hover-background);

        span {
            color: var(--hollow-button-color);
        }
    }
}

.Button {
    @include button; // importa il mixin nel file _button in abstract

    max-width: var(--button-max-width);

    @include mobile {
        max-width: 330px;
    }

    &.primary,
    &.secondary {
        width: 100%;
        max-width: var(--button-max-width);

        &.medium {
            max-width: 286px;
        }

        &.small {
            height: 40px;
            max-width: 200px;
        }
    }

    &.secondary {
        color: var(--hollow-button-color) !important;
        border-color: var(--hollow-button-border);
        background-color: var(--hollow-button-background);

        span {
            color: var(--hollow-button-color);
        }

        &:hover {
            background-color: var(--hollow-button-hover-background);
            border-color: var(--hollow-button-hover-border);
            color: var(--hollow-button-color) !important;

            span {
                color: var(--hollow-button-color);
            }
        }
    }
}

.Button.primary,
.pagebuilder-button-primary {
    &::after {
        content: var(--button-arrows);
        height: 42px;
        vertical-align: middle;
        margin-inline-start: 10px;
    }
}

button {
    background-color: transparent;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--color-black);
    }

    &.Button-Text {
        font-family: $font-base;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
        font-weight: 500;
        color: $default-primary-base-color;

        &:hover {
            color: $default-primary-light-color;
        }
    }
}
