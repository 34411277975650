/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --breadcrumbs-height: 20px;
    --breadcrumbs-active-color: #{$black};
    --breadcrumbs-color: #{$default-neutral-base-color};
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    padding-block-start: 28px;
    padding-block-end: 30px;

    @include mobile {
        --breadcrumbs-background: #fff;

        display: block;
        padding-block-start: 15px;
        padding-block-end: 15px;
    }

    &-List {
        list-style: none;
        padding: 0;
        height: 14px;
    }
}
