/** extend sidea */
/* stylelint-disable declaration-no-important */

:root {
    --font-p-mobile: 1.4rem;
    --line-height-p-mobile: 1.5;
    --font-weight-p-mobile: 400;
}

.CmsPage {
    @include mobile {
        margin-block-end: 0 !important;
    }

    &#chi-siamo {
        padding-block-start: 15px;

        @include mobile {
            padding-block-start: 5px; 
        }

        .CmsPage {
            &-Wrapper {
                @include mobile {
                    max-width: 100%;
                }
            }

            &-Heading {
                @include mobile {
                    padding-inline: 18px;
                }
            }
        }

        .ChiSiamo {
            &-TopBanner {
                margin-inline: calc(((100vw - var(--content-wrapper-nav-width)) / 2) * -1);

                @include mobile {
                    margin-inline: 0;
                }

                img {
                    @include desktop {
                        &.pagebuilder-mobile-hidden {
                            display: block;
                        }
                    }

                    @include mobile {
                        &.pagebuilder-mobile-only {
                            display: block;
                        }
                    }
                }

                .TopBanner-Caption {
                    position: absolute;
                    inset-block-start: 30%;
                    inset-inline-start: 5%;

                    @include mobile {
                        inset-block-start: 14rem;
                        width: 90%;
                    }

                    p {
                        color: $white;
                        font-size: 3rem;

                        @include ultra-narrow-desktop {
                            font-size: 2.1rem;
                        }

                        @include tablet {
                            font-size: 2.1rem;
                        }

                        @include mobile {
                            font-size: 1.8rem;
                        }

                        strong {
                            display: block;
                            font-size: 8rem;
                            line-height: 1.1;
                            margin-block-end: 30px;

                            @include ultra-narrow-desktop {
                                font-size: 5rem;
                                margin-block-end: 15px;
                            }

                            @include tablet {
                                font-size: 5rem;
                                margin-block-end: 15px;
                            }

                            @include mobile {
                                font-size: 3.9rem;
                                margin-block-end: 20px;
                            }
                        }

                        &:last-child {
                            br {
                                @include mobile {
                                    content: "";
                
                                    &::after {
                                        content: " ";
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &-BottomBanner {
                margin-inline: calc(((100vw - var(--content-wrapper-nav-width)) / 2) * -1);
                background-color: $default-primary-base-color;
                padding-block: 8.9rem 9rem;
                text-align: center;

                @include mobile {
                    margin-inline: 0;
                    padding-block: 50px;
                    padding-inline: 32px;
                }

                p {
                    font-size: 3rem;

                    @include mobile {
                        font-size: 1.8rem;
                    }

                    strong {
                        display: block;
                        font-size: 3.9rem;
                        margin-block-end: 4rem;

                        @include mobile {
                            font-size: 2.7rem;
                            margin-block-end: 3rem;
                        }
                    }
                }

                &::after {
                    content: var(--ico-frecce-grandi);
                    position: absolute;
                    inset-block-end: 0;
                    inset-inline-start: 0;

                    @include mobile {
                        inset-block-end: -158px;
                    }
                }
            }

            &-Valori {
                padding-block: 6rem;
                padding-inline: 25%;

                @include mobile {
                    margin-inline: 0;
                    padding-block: 5rem 30px;
                    padding-inline: 0;
                }

                .h1 {
                    margin-block-end: 6rem;

                    @include mobile {
                        margin-block-end: 30px;

                        p {
                            margin-block-end: 0;
                        }
                    }
                }

                .pagebuilder-column-group {
                    gap: 3.9rem;
                    padding-block-end: 3.9rem;

                    @include mobile {
                        gap: 16px;
                        padding-block-end: 16px;
                    }

                    figcaption {
                        color: $white;
                        font-size: 3.9rem;
                        font-weight: 700;
                        position: absolute;
                        inset-block-end: 38px;
                        width: 100%;
                        text-align: center;

                        @include mobile {
                            font-size: 18px;
                            inset-block-end: 17px;
                        }
                    }
                }
            }

            &-Shaka {
                padding-block: 6rem;

                @include mobile {
                    padding-block: 0 40px;
                    margin-inline: 0;
                }

                .pagebuilder-column-group {
                    @include mobile {
                        flex-direction: column;
                    }
                }

                .FirstColumn,
                .SecondColumn {
                    @include mobile {
                        width: 100% !important;
                    }
                }

                .FirstColumn {
                    background-color: #FCFCFC;
                    justify-content: flex-end;
                    flex-direction: row;

                    figure {
                        max-width: 650px;
                    }
                }

                .SecondColumn {
                    padding-inline: 9rem;
                    justify-content: center;

                    @include mobile {
                        padding-inline: 15px;
                    }

                    .h1 {
                        margin-block-end: 3.5rem;
    
                        @include mobile {
                            margin-block-end: 0;
                            margin-block-start: 20px;
                        }
                    }
                }
            }

            &-Storia {
                padding-block: 6rem 5rem;
                margin-inline: calc(((100vw - var(--content-wrapper-nav-width)) / 2) * -1);
                margin-block-end: 10rem;

                @include mobile {
                    margin-inline: 0;
                    padding-block: 50px 0;
                }

                .h1 {
                    display: block;
                    margin-block-end: 5rem;

                    @include mobile {
                        margin-block-end: 30px;
                    }
                }

                figure {
                    margin-block-end: 9rem;

                    @include mobile {
                        margin-block-end: 50px;
                    }
                }
            }
        }

        .BannerClickandCollect {
            margin-block-end: 15rem;

            @include mobile {
                margin-block-end: 50px;
            }
        }
    }

    &#click-and-collect {
        .banner-collect-page {
            margin-block-end: 170px;

            @include narrow-desktop {
                margin-block-end: 130px;
            }

            @include ultra-narrow-desktop {
                margin-block-end: 110px;
            }

            @include tablet {
                margin-block-end: 100px;
            }

            @include mobile {
                margin-block-end: 0;
                width: 100vw;
                margin-inline-start: -19px;
            }

            .pagebuilder-column-group {
                @include mobile {
                    flex-direction: column;

                    .pagebuilder-column {
                        width: 100% !important;
                    }
                }
            }
            
            figure {
                display: flex;
                justify-content: center;

                img {
                    width: 100%;
                    height: auto;

                    @include desktop-wide-1600 {
                        width: 100%;
                    }

                    @include ultra-narrow-desktop {
                        width: 100%;
                    }

                    @include tablet {
                        width: 100%;
                    }
                }
            }
        }

        .h2-mod.light {
            margin-block-end: 0;

            @include mobile {
                font-weight: 600;
                margin-block-start: 0;
                line-height: 1.5;
                font-size: 2.4rem;
            }
        }

        h4 {
            font-size: 2.1rem;
            font-weight: 600;
            margin-block-start: 0;
            margin-block-end: 15px !important;
        }

        @include mobile {
            .p16, .p16 p {
                font-size: 1.2rem;
                font-weight: var(--font-weight-p-mobile);
                line-height: 1.6 !important;
                margin-block-end: 0;

                &.cc-pw {
                    margin-block-end: 40px;
                }
            }
        }

        .text-cc {
            @include mobile {
                &.p16 {
                    margin-block-end: 0;
                }
            }
        }

        .list-steps {
            @include mobile {
                text-align: center;
                margin-block-end: 30px !important;
                max-width: 270px;

                .pagebuilder-column-group {
                    flex-direction: column;
                    gap: 10px;

                    .pagebuilder-column {
                        width: 100%;
                    }
                }
            }
            
            h2 {
                padding-inline-end: 5px;

                @include narrow-tablet {
                    position: absolute;
                    inset-inline-end: 43%;
                    z-index: 10;
                    font-size: 3.9rem;
                }

                @include mobile {
                    position: absolute;
                    inset-inline-end: 36%;
                    z-index: 10;
                    font-size: 3.9rem;
                }
            }

            figure {
                @include desktop {
                    position: absolute;
                    z-index: -1;
                    inset-inline-end: -39px;
                }

                @include mobile {
                    text-align: center;
                }

                img {
                    width: auto;
                    height: auto;

                    @include mobile {
                        width: auto;
                        margin-block-end: 0;
                    }
                }
            }

            .last-step {
                h2 {
                    @include mobile {
                        margin-block-start: 10px;
                    }
                }

                figure {
                    @include desktop {
                        inset-block-start: 20px;
                    }

                    @include narrow-desktop {
                        inset-block-start: 35px;
                    }
        
                    @include tablet {
                        inset-block-start: 35px;
                    }

                    @include narrow-tablet {
                        inset-block-start: 0;
                    }
                }
            }
        }
    }

    .contatti-negozio {
        font-weight: 300;
        margin-block-start: 0;
        margin-block-end: 8px;

        @include mobile {
            text-align: start;
            font-size: 1.6rem;
        }

        @include ultra-narrow-desktop {
            font-size: 1.8rem;
        }

        @include tablet {
            font-size: 1.8rem;
        }

        @include narrow-tablet {
            font-size: 1.8rem;
        }

        &::before {
            content: '';
            margin-inline-start: 0;
            margin-inline-end: 15px;
            vertical-align: middle;
        }

        &.phone {
            &::before {
                content: var(--icon-phone);
            }            
        }

        &.email {
            &::before {
                content: var(--icon-mail);
            }            
        }
    }

    .orari-negozio {
        font-size: 2.4rem;
        width: fit-content;
        min-width: 45%;

        @include mobile {
            font-size: 1.6rem;
        }

        p {
            font-size: 2.4rem;
            margin-block-end: 6px;
            border-bottom: 1px solid var(--border-color-gray);

            @include ultra-narrow-desktop {
                font-size: 2rem;
            }

            @include tablet {
                font-size: 2rem;
            }

            @include narrow-tablet {
                font-size: 1.8rem;
            }

            @include mobile {
                font-size: 1.6rem;
                margin-block-end: 12px;
                padding-block-end: 5px;
                word-spacing: -2px;

                strong {
                    font-weight: 600;
                }
            }
        }
    }

    .slider-negozio {
        @include mobile {
            margin: 0 -18px;
            min-width: calc(100% + 36px);
        }

        .slick-dots {
            display: none !important;

            @include mobile {
                display: block !important;
                inset-block-end: 15px;

                li {
                    margin: 0 2px;
                }
            }
        }

        .slick-next, .slick-prev {
            @include mobile {
                display: none;
            }
        }
    }

    .mappa-container {
        iframe {
            @include desktop {
                height: 665px;
            }

            @include desktop-wide-1440 {
                height: 620px;
            }

            @include narrow-desktop {
                height: 555px;
            }

            @include ultra-narrow-desktop {
                height: 400px;
            }

            @include tablet {
                height: 400px;
            }
        }
    }

    &#termini-e-condizioni {
        max-width: 1200px;
        margin: 0 auto;

        @include mobile {
            padding-block-start: 10px;  
        }

        .CmsPage-Heading {
            display: block;
            text-align: center;
        }

        .CmsPage-Content {
            padding-block-start: 30px;

            @include mobile {
                padding-block-start: 0; 
            }

            .container {
                max-width: 1180px;

                @include ultra-narrow-desktop {
                    max-width: 990px;
                }

                @include mobile {
                    max-width: 100%;
                }
            }

            p {
                font-size: 1.6rem;
                line-height: 1.5;

                @include mobile {
                    line-height: 1.5;
                }

                strong {
                    display: block;
                    margin-block-start: 30px;

                    @include mobile {
                        margin-block-start: 20px;
                    }
                }
            }

            .pagebuilder-button-link {
                line-height: 1.6;
            }

            .pagebuilder-column-group {
                @include mobile {
                    display: block;
                }

                .pagebuilder-column {
                    width: 100% !important; /* stylelint-disable-line */
                }
            }

            .Indice {
                display: flex;
                flex-wrap: wrap;

                &-Single {
                    flex: 0 0 50%;
                    line-height: 1.7;
                    font-size: 1.6rem;
                }
            }
        }
    }

    &#privacy-policy {
        max-width: 1200px;
        margin: 0 auto;

        @include mobile {
            padding-block-start: 10px;  
        }

        .CmsPage-Heading {
            display: block;
            text-align: center;
            margin-block-end: 45px;
        }
    }
}
