/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Style component Sidea */

.Infosped-Popup {
    .Popup {
        &-Header {
            @include mobile {
                margin-block-end: 60px;
            }
        }

        &-CloseBtn {
            inset-inline-end: -30px;

            @include mobile {
                inset-inline-end: 0;
                inset-block-start: 20px;
            }
        }

        &-Content {
            background-color: #fff;
            border-radius: 0;
            width: 50%;
            min-width: 950px;
            overflow-x: hidden;
            max-height: unset;
            overflow-y: auto;

            @include mobile {
                min-width: 100%;
            }

            img {
                vertical-align: middle;
                margin-inline-end: 15px;
            }

            a {
                text-decoration: underline;
            }

            .InfoSpedizione {
                &-Title {
                    @include mobile {
                        margin-block: 0;
                    }

                    p {
                        @include mobile {
                            margin-block-start: 0;
                        }
                    }
                }

                &-TitleReso {
                    @include mobile {
                        font-size: 1.8rem;
                    }
                }
            }
        }
    }
}
