/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override style */

:root {
    --header-wrapper-height: 184px;
    --header-top-links-height: 35px;
    --header-top-menu-height: 50px;
    --header-logo-height: 50px;
    --header-logo-width: 225px;
    --topmenu-height: 60px;
    --icons-height: 25px;

    @include desktop-wide-1440 {
        --header-wrapper-height: 182px;
    }

    @include narrow-desktop {
        --header-wrapper-height: 180px;
    }

    @include ultra-narrow-desktop {
        --header-wrapper-height: 180px;
        --header-top-menu-height: 40px;
        --topmenu-height: 50px;
    }

    @include tablet {
        --header-wrapper-height: 180px;
    }

    @include mobile {
        --topmenu-height: 52px;
        --header-wrapper-height: 88px;
    }
}

.Header {
    position: fixed;
    z-index: 99;
    inset-block-start: 0;
    border-bottom: none;
    -webkit-transition: top 0.1s ease-out;
    -moz-transition: top 0.1s ease-out;
    -o-transition: top 0.1s ease-out;
    transition: top 0.1s ease-out;

    & > * {
        max-width: 100vw;
        width: 100%;
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-wrapper-height);

        &.Header-isCheckout {
            margin-block-end: 130px;
        }
    }

    &.sticky {
        @include desktop {
            inset-block-start: calc(var(--header-top-links-height) * -1);
        }
    }

    &-TopLinks {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background-color: $black;
        color: $white;
        font-size: 12px;
        height: var(--header-top-links-height);
        padding-block-end: 5px;
        padding-inline-start: calc( (100% - var(--content-wrapper-nav-width)) / 2);
        padding-inline-end: calc( (100% - var(--content-wrapper-nav-width)) / 2);

        @include ultra-narrow-desktop {
            font-size: 11px; 
        }

        @include tablet {
            font-size: 11px;
        }

        .TopLinks {
            &-Stores {
                font-size: 1.6rem;
                font-weight: bold;

                svg {
                    margin-inline-end: 9px;
                }
            }
        }

        #conveythis {
            max-width: 60px;
            position: absolute;
            inset-inline-end: 0;

            > div:first-child {
                height: 20px !important;
            }
        }

        #conveythis-wrapper {
            position: relative !important;
        }

        .conveythis-widget-main {
            border: none !important;
            width: 60px !important;
            border-radius: 0 !important;
        }

        .conveythis-widget-current-language-wrapper {
            .conveythis-widget-language {
                background-color: #fff !important;
                height: 20px !important;

                &:hover {
                    div:first-child {
                        color: var(--link-hover) !important;
                    }

                    .conveythis-language-arrow {
                        background-color: var(--link-hover) !important;
                    }
                }

                div:first-child {
                    width: auto !important;
                    font-size: 1.3rem !important;
                    font-family: $font-base !important;
                    line-height: 20px !important;
                    height: 20px !important;
                }
            }
        }

        .conveythis-language-arrow {
            transform: rotate(90deg) !important;
            height: 20px !important;
        }

        .conveythis-widget-languages {
            .conveythis-widget-language {
                background-color: #fff !important;

                div:first-child {
                    line-height: 30px !important;
                    height: 30px !important;
                    width: auto !important;
                    font-size: 1.3rem !important;
                    font-family: $font-base !important;
                    
                    &:hover {
                        color: var(--link-hover) !important;
                    }
                }
            }

            > div:last-child {
                display: none !important;
            }
        }

        a {
            color: $white;
            margin-inline-start: 3px;

            &:hover {
                color: $default-primary-base-color;
            }
        }

        @include mobile {
            display: none;
        }
    }

    .Logo {
        display: flex;
    }

    &-Switcher {
        align-items: center;
        display: flex;
    }

    &-Nav {
        display: flex;

        @include desktop {
            align-items: flex-end;
            justify-content: space-between;
            max-width: 100%;
            padding-inline: calc( (100% - var(--content-wrapper-nav-width)) / 2);
            height: auto;
            border-bottom: 1px solid var(--border-color-gray);
        }

        &.Homepage {
            border-bottom: none;
        }
    }

    &-BottomBanner {
        background-color: $black;
        color: $default-primary-base-color;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        font-size: 2rem;
        letter-spacing: -0.02rem;
        font-weight: 500;
        text-transform: uppercase;

        .BottomBanner-Cart {
            color: $white;
            font-size: 2.1rem;
            font-weight: bold;
            text-transform: none;
        }

        span {
            p {
                color: $default-primary-base-color;
                font-size: 2rem;
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: -0.02rem;
                margin: 0;

                @include mobile {
                    font-size: 12px;
                    letter-spacing: normal;
                }
            }
        }

        @include mobile {
            height: 34px;
            font-size: 12px;
        }

        .slick-slider {
            width: 100% !important;
            text-align: center;
        }
    }

    .BottomBanner {
        &-Membership {
            cursor: pointer;
        }
    }

    .NavigationTabs {
        @include mobile {
            &_isHidden {
                display: block;
            }
        }
    }

    &-MyAccount {
        height: auto;

        button,
        a {
            @include desktop {
                cursor: pointer;
                vertical-align: bottom;
                font-weight: 500;
                font-size: 12px;
            }

            @include narrow-desktop {
                font-size: 11px;
            }

            @include tablet {
                font-size: 11px;
            }

            &:hover span {
                color: var(--primary-base-color);
            }
        }
    }

    &-MyAccountContainer {
        margin-inline-start: 0;
        padding-inline-end: 0 !important;
        height: var(--icons-height);
        align-items: center;
        
        @include ultra-narrow-desktop {
            padding-inline-end: 0 !important;
        }

        @include tablet {
            padding-inline-end: 0 !important;
        }
    }

    &-LogoWrapper {
        position: relative;
        width: auto;
        max-height: 60px;

        @include desktop {
            height: 100%;
            padding-block-end: calc(var(--menu-item-bottom-padding) - 6px);
            min-height: fit-content;
        }

        @include narrow-desktop {
            max-width: 200px;
        }

        @include ultra-narrow-desktop {
            padding-block-end: calc(var(--menu-item-bottom-padding) - 3px);
            max-width: 180px;
            overflow: visible;
        }

        @include tablet {
            padding-block-end: calc(var(--menu-item-bottom-padding) - 3px);
            max-width: 180px;
            overflow: visible;
        }

        .Image-Image {
            width: auto;
            height: auto;
            position: relative;

            @include desktop-wide-1600 {
                max-width: 100%;
            }

            @include desktop-wide-1440 {
                max-width: 82%;
            }

            @include narrow-desktop {
                max-width: 90%;
            }

            @include ultra-narrow-desktop {
                max-width: 90%;
            }

            @include tablet {
                max-width: 90%;
            }
        }
    }

    &-IconsWrapper {
        align-items: flex-end;
        justify-content: flex-end;
        gap: 25px;
        font-weight: 500;
        margin-block-end: 20px;

        @include desktop-wide-1600 {
            gap: 16px;
        }

        @include desktop-wide-1440 {
            gap: 10px;
        }

        @include narrow-desktop {
            gap: 10px;
        }

        @include tablet {
            gap: 10px;
        }
        
        > div {
            padding-inline-end: 0;
            line-height: 1;

            &:first-child {
                padding-inline-start: 0;
            }

            &:last-child,
            &:nth-last-child(2) {
                border-right: none;
            }

            &:last-child {
                @include ultra-narrow-desktop {
                    padding-inline-end: 0;
                }

                @include tablet {
                    padding-inline-end: 0;
                }
            }
        }

        svg {
            @include ultra-narrow-desktop {
                transform: scale(0.9);
            }

            @include tablet {
                transform: scale(0.9);
            }
        }
    }

    &-WishlistButtonWrapper,
    &-StoreButtonWrapper {
        margin-inline-start: 0;

        a {
            all: unset;
            cursor: pointer;

            @include narrow-desktop {
                font-size: 11px;
            }

            @include tablet {
                font-size: 11px;
            }

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-StoreButtonWrapper {
        display: flex;
        align-items: flex-end;
        padding-block-end: 2px;
        margin-inline-end: 4px;
        font-size: 1.6rem;
        font-weight: 400;
        height: var(--icons-height);
                
        &::after {
            content: "|";
            font-size: 14px;
            margin-inline-start: 20px;
            vertical-align: bottom;
            display: inline-block;
            margin-block-end: 0;

            @include desktop-wide-1440 {
                margin-inline-start: 10px;
            }

            @include ultra-narrow-desktop {
                margin-inline-start: 10px;
                margin-block-end: 0px;
            }

            @include tablet {
                margin-inline-start: 10px;
                margin-block-end: 0px;
            }
        }

        @include tablet {
            padding-inline-end: 0;
        }

        svg {
            transform: scale(0.85);
            margin-inline-end: 8px;
            margin-block-end: -3px;

            @include tablet {
                transform: scale(0.7);
                vertical-align: bottom;
            }

            path {
                stroke: $black;
            }
        }
    }

    &-WishlistButtonWrapper {
        height: var(--icons-height);
        padding-inline-end: 0;

        a {
            display: block;
        }

        svg {
            transform: scale(0.65);

            @include ultra-narrow-desktop {
                transform: scale(0.6);
            }

            @include tablet {
                transform: scale(0.6);
            }

            path {
                fill: $black;
            }

            &:hover {
                path {
                    fill: $default-primary-dark-color;
                }
            }
        }
    }

    &-MinicartButtonWrapper span {
        color: var(--primary-base-color);
        font-size: 12px;
        font-weight: 700;

        @include narrow-desktop {
            font-size: 11px;
        }

        @include tablet {
            font-size: 11px;
        }

        &:hover {
            color: $black;
        }

        &.Header-MinicartItemCount {
            font-family: $font-base;
            color: $white;
            text-align: center;
            inset-inline-start: 11px;
            inset-inline-end: auto;
            inset-block-start: -6px;

            &::before {
                display: none;
            }
        }
    }
 
    &-Button {
        &_type {
            &_minicart {
                padding-inline-end: 0;
                
                @include desktop {
                    height: var(--icons-height) !important; /* stylelint-disable-line declaration-no-important */
                    width: auto;
                    margin-inline-start: 0;
                }

                @include tablet {
                    padding-inline-start: 0;
                }

                .CartIcon {
                    fill: var(--primary-base-color);
                    width: 21px;
                    height: 21px;
                    vertical-align: middle;
                }

                svg {
                    &:hover {
                        path {
                            fill: $default-primary-dark-color;
                        }
                    }
                }
            }
        }
    }

    .SearchField {
        @include mobile {
            position: absolute;
            z-index: -1;
            inset-block-start: var(--header-wrapper-height);
            display: block;
            width: 100%;
            background-color: $default-neutral-dark-color;
            padding-inline: 0;
            padding-block-end: 0;
            margin-block: 0;
            margin-inline-start: 0;
            transform: translateY(-88px);
            -webkit-transition: all .2s ease-in-out;
            -moz-transition: all .2s ease-in-out;
            -o-transition: all .2s ease-in-out;
            -ms-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
        }

        &.isVisible {
            @include mobile {
                transform: translateY(0);
            }
        }
    }

    .MenuSearch-Wrapper {
        display: flex;
        grid-gap: 16px;
        gap: 16px;
        align-items: flex-end;
        position: unset;

        @include narrow-desktop {
            grid-gap: 10px;
            gap: 10px;
        }

        @include tablet {
            grid-gap: 10px;
            gap: 10px;
        }
    }

    .Menu {
        @include desktop {
            &-MainCategories {
                padding-inline-start: 0;
                width: auto;
            }

            &-MenuWrapper {
                margin-block-start: 0;
                max-width: var(--content-wrapper-large);
                display: flex;
                align-items: flex-end;
                position: unset;
            }

            &-ItemList {
                &_type_subcategory {
                    height: auto;
                }
            }

            &-RightButtonsWrapper {
                --button-height-mini: 35px;
                
                @include ultra-narrow-desktop {
                    --button-height-mini: 30px;
                }
                
                .Button {
                    height: var(--button-height-mini);
                    min-width: 172px;
                    margin-inline-start: 3px;

                    @include desktop-wide-1440 {
                        min-width: 150px;
                    }

                    @include narrow-desktop {
                        min-width: 150px;
                    }

                    @include ultra-narrow-desktop {
                        min-width: 120px;
                        height: 30px;
                    }

                    @include tablet {
                        min-width: 120px;
                        height: 30px;
                    }

                    &.newProducts {
                        background-color: $default-secondary-light-color;
                        border-color: $default-secondary-light-color;
                        color: $default-secondary-base-color;

                        &:hover {
                            background-color: $default-secondary-base-color;
                            border-color: $default-secondary-base-color;
                            color: $white;
                        }
                    }

                    &.sales {
                        background-color: $default-primary-light-color;
                        border-color: $default-primary-light-color;
                        color: $default-primary-base-color;

                        &:hover {
                            background-color: $default-primary-base-color;
                            border-color: $default-primary-base-color;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    &_isCheckout {
        position: relative;
        margin-block-end: -184px;

        .Header-TopLinks {
            display: none;
        }
        
        .Header-LogoWrapper {
            max-width: 300px;
            padding-block-end: 0;

            .Logo {
                width: auto;
            }
        }
    }
}
