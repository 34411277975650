/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** Component Sidea - bottom fixed bar mobile */

.NavigationTabsBottom {
    &-Wrapper {
        @include mobile {
            position: fixed;
            z-index: 20;
            inset-block-start: calc(100% - 45px);
            inset-inline-start: 0;
            width: 100vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: $default-neutral-base-color;
            color: $white;
            height: 45px;
            padding-inline: 18px;
            padding-block-start: 3px;
        }

        .UserIcon,
        .HeartIcon {
            path,
            rect {
                fill: $white;
                stroke: $white;
            }
        }

        .CartIcon {
            width: 22px;
            height: 22px;

            path,
            rect {
                fill: $white;
            }
        }

        .HeartIcon {
            height: 17px;
            min-width: 19px;
            width: 21px;
        }
    }
}
