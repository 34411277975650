/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.AmastyCustomerInfo {
    padding-inline-start: 10px;

    @include mobile {
        padding-inline-start: 0;
    }
    
    h4 {
        margin-block-start: 0;
        margin-block-end: 25px;

        @include narrow-desktop {
            margin-block-end: 18px;
        }

        @include tablet {
            margin-block-end: 15px;
            font-size: 1.8rem;
        }

        @include mobile {
            margin-block-end: 15px;
            font-size: 1.8rem;
        }
    }

    &-ContainerInfo {
        display: flex;
        margin-block-end: 8px;

        @include ultra-narrow-desktop {
            margin-block-end: 3px;
        }

        @include tablet {
            margin-block-end: 3px;
        }

        @include mobile {
            margin-block-end: 3px;
        }

        .First {
            min-width: 120px;
            margin-inline-end: 40px;

            @include mobile {
                min-width: 90px;
            }
        }

        span {
            font-size: 1.6rem;
            font-weight: normal;
            color: #000000;

            @include narrow-desktop {
                font-size: 1.5rem;
            }

            @include tablet {
                font-size: 1.5rem;
            }

            @include mobile {
                font-size: 1.5rem;
            }
        }
    }

    &-AddressInfo {
        margin-inline-start: 80px;

        @include narrow-desktop {
            margin-inline-start: 60px;
        }

        @include tablet {
            margin-inline-start: 50px;
        }

        @include mobile {
            margin-inline-start: 0;
        }

        p {
            line-height: 1.8;

            @include narrow-desktop {
                font-size: 1.5rem;
                line-height: 1.6;
            }

            @include tablet {
                font-size: 1.5rem;
                line-height: 1.6;
            }

            @include mobile {
                font-size: 1.5rem;
                line-height: 1.6;
            }
        }
    }
}