/** override sidea */

$white: #fff;
$default-primary-base-color:  #00ACE2;
$default-primary-dark-color: #03ACE2;
$default-primary-light-color: #00ACE2;
$default-secondary-base-color: #000000;
$default-secondary-dark-color: #3A3939;
$default-secondary-medium-color: #707070;
$default-secondary-light-color: #3B3B3B;
$default-neutral-base-color: #878787;
$default-neutral-dark-color: #EBEFF2;
$default-neutral-light-color: #F7F7F7;
$default-neutral-extralight-color: #FAFAFA;
$default-button-disabled: #C1C1C1;
$black: #000000;
$font-muli: 'Ubuntu', sans-serif;
$font-base: 'Ubuntu', sans-serif;
$font-secondary: 'Roboto', sans-serif;
$font-body-size: 1.6rem;
$font-standard-size: 62.5%;
