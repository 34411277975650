/* extend Sidea - blocco banners categorie in homepage */

.SectionTitle {
    margin-block-start: 0;
    margin-block-end: 2.7rem;

    @include mobile {
        text-align: start;
        margin-block-end: 15px;
    }
}

.BloccoCategorie {
    margin-block-end: 9rem;

    @include mobile {
        margin-block-end: 40px;
    }

    .SectionTitle {
        margin-block-end: 4.5rem;

        @include mobile {
            margin-block-end: 15px;
            text-align: center;
        }
    }

    .pagebuilder-column-group {
        gap: 10px;
        margin-block-end: 10px;

        @include mobile {
            gap: 3px;
            margin-block-end: 3px;
            flex-wrap: wrap;
        }

        &:last-child {
            @include mobile {
                width: calc(50% - 0.18rem);
                justify-content: flex-end;
                position: absolute;
                inset-inline-end: 0;
                inset-block-end: 0;

                .BloccoCategorie-Singola {
                    width: 100% !important;
                }
            }
        }
    }

    &-Singola {
        @include mobile {
            width: calc(50% - 0.15rem) !important;
        }

        figure {
            @include mobile {
                height: 100%;
            }

            &:hover {
                img {
                    filter: brightness(85%);
                }
            }

            figcaption {
                font-size: 6rem;
                font-weight: 700;
                color: $white;
                position: absolute;
                inset-block-end: 25px;
                inset-inline-start: 40px;

                @include narrow-desktop {
                    font-size: 5rem;
                }

                @include ultra-narrow-desktop {
                    font-size: 5rem;
                    inset-inline-start: 25px;
                }

                @include tablet {
                    font-size: 5rem;
                    inset-inline-start: 25px;
                }

                @include mobile {
                    font-size: 25px;
                    inset-block-end: 8px;
                    inset-inline-start: 9px;
                }
            }
        }

        &::after {
            content: '';
            width: 87px;
            height: 115px;
            position: absolute;
            background-image: var(--ico-freccia-destra);
            background-repeat: no-repeat;
            background-position: bottom right;
            background-size: contain;
            inset-block-end: 0;
            inset-inline-end: 0;

            @include mobile {
                width: 33px;
                height: 42px;
            }
        }
    }
}

.BloccoProdottiNovita {
    @include desktop {
        margin-block-end: 12rem;
    }

    @include mobile {
        margin-block-end: 79px;
    }
}
