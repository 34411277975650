/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/** override Sidea */

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, .44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
                padding: 16px;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        z-index: 110;
        width: 150%;

        .NoResults {
            padding-inline-start: 20px;
        }

        @include desktop {
            padding-block-start: 20px;
            padding-block-end: 10px;
            padding-inline-end: 0;
            position: absolute;
            inset-block-start: 38px;
            width: 150%;
            box-shadow: 0 8px 10px rgba(0, 0, 0, .1);
            border: 1px solid #f2f2f2;

            ul {
                max-height: 40vh;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                    margin-inline-end: 6px;
                }
                
                &::-webkit-scrollbar-track {
                    background-color: #c9c9c9;
                    border-radius: 3px;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 3px;
                    background-color: #656565;
                }

                .SearchItem {
                    padding: 5px 18px;

                    &-Wrapper {
                        --thumb-image-height: 50px;

                        grid-template-columns: var(--thumb-image-height) calc(100% - 60px);

                        .Image {
                            &_ratio_square {
                                padding-block-end: 0;
                                max-height: var(--thumb-image-height);
                            }

                            img {
                                height: auto;
                            }

                            &::after {
                                content: " ";
                                display: block;
                                width: 100%;
                                height: 100%;
                                background-color: #ebeff2;
                                position: absolute;
                                inset-block-start: 0;
                                inset-inline-start: 0;
                                z-index: 10;
                                opacity: .7;
                                mix-blend-mode: multiply;
                            }
                        }

                        .SearchItem-Content {
                            min-height: var(--thumb-image-height);
                        }
                    }

                    h4 {
                        font-size: 1.4rem;
                        font-weight: 400;
                        line-height: 1.3;
                        margin-block: 0;
                    }
                }
            }
        }
    }
}
