/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MembershipPopupForm {
    padding-inline: 15px;

    &-Input {
        margin: 0;
        height: 50px;
        margin-block-end: 5px;

        input {
            height: 50px;
        }

        .Field-LabelContainer {
            display: none;
        }
    }

    &-Required {
        display: flex;
        justify-content: flex-end;

        p {
            color: $white;
            margin: 0;
            font-size: 9px;
        }
    }

    .Button {
        width: 100%;
        max-width: 350px;
        background-color: $default-primary-dark-color;
        border: 1px solid $default-primary-dark-color;
        text-transform: uppercase;
        margin-block-start: 15px;
    }
}
