/* extend Sidea - blocco banners 3 collezioni in homepage */

.BloccoCollezioni {
    margin-block-end: 8.5rem;

    @include mobile {
        margin-block-end: 40px;
    }

    .pagebuilder-column-group {
        gap: 10px;

        @include mobile {
            flex-direction: column;
            gap: 0;
        }
    }

    &-Singola {
        @include mobile {
            width: 100% !important;
        }

        figure {
            @include mobile {
                height: 100%;
            }

            &:hover {
                img {
                    filter: brightness(85%);
                }
            }
        }
        
        .ButtonWrapper {
            position: absolute;
            inset-block-end: 35px;
            width: 100%;
        }          
    } 
}
